<div class="resource-title">
  <h1 [ngbPopover]="structTypeDescription" class="text-center text-white pb-2 drop-shadow" id="{{ structTypeId }}-header" triggers="hover">
    {{ structTypeId }}
  </h1>
  <button (click)="createResource()" class="btn btn-primary" id="create-{{ structTypeId }}-button">
    <i class="bi bi-plus-circle"></i>
  </button>
</div>
<app-instances-list
  (deleteInstanceEmitter)="deleteResource($event)"
  (downloadInstanceEmitter)="downloadResource($event)"
  (duplicateInstanceEmitter)="duplicateResource($event)"
  (selectInstanceEmitter)="openResource($event)"
  (tagSelectedEmitter)="onTagSelected($event)"
  *ngIf="!loading && resources.length > 0"
  [allInstances]="resources"
  [allTags]="allTags"
  [fieldsToDisplay]="fieldsToDisplay"
  [tagsPerInstance]="tagsPerResource"
/>

<ng-template #duplicationCompleteModal let-modal>
  <app-duplication-complete-modal
    (closeModal)="modalService.dismissAll()"
    (openInstance)="openResource(newResource!)"
    [info]="{ name: resourceName(newResource!) }"
  />
</ng-template>
