<div class="card">
  <div class="card-header justify-content-between d-flex">
    <div class="align-content-center">Variable Comparison</div>
    <button (click)="onDelete()" *ngIf="showDelete" class="btn btn-danger">
      <i class="bi bi-trash"></i>
    </button>
  </div>

  <div class="p-2">
    <div>
      Variable 1:

      <div class="d-flex">
        <select (change)="onVariableChange('variable1')" [(ngModel)]="variable1" class="form-select">
          <option *ngFor="let variable of variables1" [ngValue]="variable.variableRef">{{ variable.name }}</option>
        </select>

        <button (click)="onViewStruct(currentVariable1)" *ngIf="currentVariable1" class="btn btn-primary mx-1">
          <i class="bi-box-arrow-up-right"></i>
        </button>

        <button (click)="onAddNewVariable()" class="btn btn-primary">
          <i class="bi-plus-circle"></i>
        </button>
      </div>
    </div>

    <div *ngIf="operators.length > 0">
      Operator:

      <select (change)="onVariableChange('operator')" [(ngModel)]="operator" class="form-select">
        <option *ngFor="let operator of operators" [ngValue]="operator.optionId">{{ operator.label }}</option>
      </select>

      <div class="d-flex">
        <input
          (change)="onSwitchCompareWithVariable()"
          [ngModel]="compareWithVariable"
          class="form-check-input me-1"
          role="switch"
          type="checkbox"
        />
        Compare with another variable
      </div>
    </div>

    <div *ngIf="operators.length > 0 && compareWithVariable">
      Variable 2:
      <div class="d-flex">
        <select (change)="onVariableChange('variable2')" [(ngModel)]="variable2" class="form-select">
          <option *ngFor="let variable of variables2" [ngValue]="variable.variableRef">{{ variable.name }}</option>
        </select>

        <button (click)="onViewStruct(currentVariable2)" *ngIf="currentVariable2" class="btn btn-primary mx-1">
          <i class="bi-box-arrow-up-right"></i>
        </button>

        <button (click)="onAddNewVariable()" class="btn btn-primary">
          <i class="bi-plus-circle"></i>
        </button>
      </div>
    </div>

    <div *ngIf="operators.length > 0 && !compareWithVariable">
      Value:
      <input
        (change)="update()"
        *ngIf="currentVariable1 && currentVariable1.valueType === VariableType.String"
        [(ngModel)]="value"
        class="form-control"
        type="text"
      />

      <input
        (change)="update()"
        *ngIf="currentVariable1 && currentVariable1.valueType === VariableType.Number"
        [(ngModel)]="value"
        class="form-control"
        type="number"
      />

      <div *ngIf="currentVariable1 && currentVariable1.valueType === VariableType.Boolean" class="form-check form-switch w-100">
        <input (change)="update()" [(ngModel)]="value" class="form-check-input" role="switch" type="checkbox" />
      </div>

      <div *ngIf="currentVariable1 && currentVariable1.valueType === VariableType.Color" class="form-check form-switch w-100">
        <input
          (colorPickerSelect)="update($event)"
          [colorPicker]="String(value)"
          [cpAlphaChannel]="'always'"
          [cpEyeDropper]="true"
          [cpOKButtonText]="'Save'"
          [cpOKButton]="true"
          [cpOutputFormat]="'hex'"
          [style.background]="value"
          [value]="value"
          style="width: 100px"
        />
      </div>
    </div>
  </div>
</div>
