import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { DataInstanceRepository, EnumTypeRepository } from '@services/repositories';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    private dataInstanceRepository: DataInstanceRepository,
    private enumTypeRepository: EnumTypeRepository,
    private router: Router,
  ) {}

  /**
   * A method to find the url of a data instance, if it exists. Also handles special cases like MissionInfo and Modules.
   * @param uid
   */
  public async findDataInstanceUrl(uid: string): Promise<[string[], NavigationExtras?]> {
    const dataInstance = await this.dataInstanceRepository.get(uid);
    const [parent, traversedChildren] = await dataInstance.getParent();

    switch (parent.dataType) {
      case 'MissionInfo': {
        const activityEnum = await this.enumTypeRepository.get('Activity');
        const activity = activityEnum.options.includes(dataInstance.dataType)
          ? await dataInstance.identifier
          : await traversedChildren.find((child) => activityEnum.options.includes(child.dataType))?.identifier;

        return [
          [`/home/MissionInfo/${await parent.identifier}`],
          {
            queryParams: {
              activityNode: activity,
            },
          },
        ];
      }

      case 'Module': {
        return [
          [`/home/Module/${await parent.identifier}`],
          {
            queryParams: {
              kennisNode: await dataInstance.identifier,
            },
          },
        ];
      }

      default: {
        return [[`/home/${parent.dataType}/${await parent.identifier}`]];
      }
    }
  }

  public async navigateToDataInstance(uid: string) {
    const [url, extras] = await this.findDataInstanceUrl(uid);
    await this.router.navigate(url, extras);
  }
}
