import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Tag } from '@services/entities';
import { TagRepository } from '@services/repositories';
import { GeneratedTag } from '@services/types/generated';
import { BootstrapClass } from '../../models/types/BootstrapClass';
import { Color } from '@services/utils/Color';
import { AlertService } from '@services/UI-elements/alert-service';
import { ConfirmationModalService } from '@services/UI-elements/confirmation-modal.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
})
export class TagsComponent implements OnInit, OnChanges {
  @Input() tags: Tag[] = [];
  @Input() allTags: Tag[] = [];
  @Input() tagScope: GeneratedTag.ScopeEnum = GeneratedTag.ScopeEnum.Instance;
  @Output() tagSelected: EventEmitter<Tag> = new EventEmitter<Tag>();

  filteredTags: Tag[] = [];

  newTagName = '';
  newTagColor = '';
  editTagName = '';
  editTagColor = '';
  editingTag?: Tag = undefined;

  protected readonly Color = Color;

  constructor(
    private tagRepository: TagRepository,
    private alertService: AlertService,
    private confirmService: ConfirmationModalService,
  ) {}

  ngOnInit() {
    this.sortTags();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['allTags']) {
      this.filterTags(this.newTagName);
    }
  }

  async addTag() {
    if (!this.newTagName) return;

    if (this.allTags.some((tag) => tag.name === this.newTagName)) {
      this.alertService.showAlert('Tag with name ' + this.newTagName + ' already exists', BootstrapClass.DANGER);
      return;
    }

    if (!this.newTagColor) this.newTagColor = Color.getRandomColor();

    const tag = await this.tagRepository.create({
      name: this.newTagName,
      color: this.newTagColor,
      uid: '_',
      scope: this.tagScope,
    });

    await this.selectTag(tag);

    this.newTagName = '';
    this.newTagColor = '';
  }

  async selectTag(tag: Tag) {
    if (this.isTagSelected(tag)) {
      this.tags.splice(
        this.tags.findIndex((t) => t.uid === tag.uid),
        1,
      );
    } else {
      this.tags.push(await this.tagRepository.get(tag.uid));
    }

    this.sortTags();

    this.tagSelected.emit(tag);
  }

  async updateTag(newTag: Tag | GeneratedTag) {
    if (!this.editTagName || !this.editTagColor) return;

    if (this.editTagName !== newTag.name && this.allTags.some((tag) => tag.name === this.editTagName)) {
      this.alertService.showAlert('Tag with name ' + this.editTagName + ' already exists', BootstrapClass.DANGER);
      return;
    }

    const tag = await this.tagRepository.get(newTag.uid);
    tag.name = this.editTagName;
    tag.color = this.editTagColor;

    // Manual save, so we ensure the tag is updated before we reload the page
    await this.tagRepository.save(tag);

    location.reload();
  }

  async deleteTag(tag: Tag) {
    if (!(await lastValueFrom(this.confirmService.confirm('Are you sure you want to delete the tag ' + tag.name + '?')))) return;

    await this.tagRepository.delete(tag);
  }

  sortTags() {
    this.tags.sort((a, b) => a.name.localeCompare(b.name));
  }

  isTagSelected(tag: GeneratedTag) {
    return this.tags.some((t) => t.name === tag.name);
  }

  filterTags(filterValue: string) {
    if (!filterValue) {
      this.filteredTags = this.allTags.filter((tag) => !this.isTagSelected(tag) && !tag.isDefault);
      return;
    }
    this.filteredTags = this.allTags.filter(
      (tag) => tag.name.toLowerCase().includes(filterValue.toLowerCase()) && !this.isTagSelected(tag) && !tag.isDefault,
    );
  }
}
