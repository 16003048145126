import { Component, ElementRef, HostListener, TrackByFunction, ViewChild } from '@angular/core';
import { NgClass, NgForOf, NgIf, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { debounce, instant } from '@services/utils';
import { SpinnerComponent } from '../spinner/spinner.component';
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { SearchService } from '@services/UI-elements/search.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { NavigationService } from '@services/navigation.service';
import { GeneratedSearchResponse, GeneratedSearchResult } from '@services/types/generated';

// Because angular thinks it's funny to remove a bunch of shit from the dom, we have to add it back
const SVG_PROPERTIES = 'style="padding: 0.5rem; fill: currentColor" width="100%" height="100%"' as const;

@Component({
  selector: 'app-search-popover',
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    NgForOf,
    NgOptimizedImage,
    NgTemplateOutlet,
    SpinnerComponent,
    CdkVirtualScrollViewport,
    CdkVirtualForOf,
    CdkFixedSizeVirtualScroll,
    NgClass,
  ],
  templateUrl: './search-popover.component.html',
  styleUrl: './search-popover.component.scss',
})
export class SearchPopoverComponent {
  @ViewChild('searchContainer') searchContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('input') input?: ElementRef<HTMLInputElement>;
  @ViewChild('viewport') viewport?: CdkVirtualScrollViewport;

  protected shown = false;
  protected debouncedSearch = debounce(() => this.search(), 250);
  protected isLoading = false;

  protected searchString = '';
  protected searchResponse?: GeneratedSearchResponse = undefined;

  protected scrolledIndex = -1;

  // Because of several reasons including angular being a bit annoying sometimes, we need to keep a list of icons
  //  as svgs, otherwise angular change-detecting won't work.
  protected readonly iconLut: Record<GeneratedSearchResult.TypeEnum, string> = {
    FIELD_VALUE: `<svg ${SVG_PROPERTIES} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M290.8 48.6l78.4 29.7L288 109.5 206.8 78.3l78.4-29.7c1.8-.7 3.8-.7 5.7 0zM136 92.5l0 112.2c-1.3 .4-2.6 .8-3.9 1.3l-96 36.4C14.4 250.6 0 271.5 0 294.7L0 413.9c0 22.2 13.1 42.3 33.5 51.3l96 42.2c14.4 6.3 30.7 6.3 45.1 0L288 457.5l113.5 49.9c14.4 6.3 30.7 6.3 45.1 0l96-42.2c20.3-8.9 33.5-29.1 33.5-51.3l0-119.1c0-23.3-14.4-44.1-36.1-52.4l-96-36.4c-1.3-.5-2.6-.9-3.9-1.3l0-112.2c0-23.3-14.4-44.1-36.1-52.4l-96-36.4c-12.8-4.8-26.9-4.8-39.7 0l-96 36.4C150.4 48.4 136 69.3 136 92.5zM392 210.6l-82.4 31.2 0-89.2L392 121l0 89.6zM154.8 250.9l78.4 29.7L152 311.7 70.8 280.6l78.4-29.7c1.8-.7 3.8-.7 5.7 0zm18.8 204.4l0-100.5L256 323.2l0 95.9-82.4 36.2zM421.2 250.9c1.8-.7 3.8-.7 5.7 0l78.4 29.7L424 311.7l-81.2-31.1 78.4-29.7zM523.2 421.2l-77.6 34.1 0-100.5L528 323.2l0 90.7c0 3.2-1.9 6-4.8 7.3z"/></svg>`,
    FILE: `<svg ${SVG_PROPERTIES} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 288c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128z"/></svg>`,
    VARIABLE: `<svg ${SVG_PROPERTIES} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M448 80l0 48c0 44.2-100.3 80-224 80S0 172.2 0 128L0 80C0 35.8 100.3 0 224 0S448 35.8 448 80zM393.2 214.7c20.8-7.4 39.9-16.9 54.8-28.6L448 288c0 44.2-100.3 80-224 80S0 332.2 0 288L0 186.1c14.9 11.8 34 21.2 54.8 28.6C99.7 230.7 159.5 240 224 240s124.3-9.3 169.2-25.3zM0 346.1c14.9 11.8 34 21.2 54.8 28.6C99.7 390.7 159.5 400 224 400s124.3-9.3 169.2-25.3c20.8-7.4 39.9-16.9 54.8-28.6l0 85.9c0 44.2-100.3 80-224 80S0 476.2 0 432l0-85.9z"/></svg>`,
    DATA_INSTANCE: `<svg ${SVG_PROPERTIES} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M181.3 32.4c17.4 2.9 29.2 19.4 26.3 36.8L197.8 128l95.1 0 11.5-69.3c2.9-17.4 19.4-29.2 36.8-26.3s29.2 19.4 26.3 36.8L357.8 128l58.2 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-68.9 0L325.8 320l58.2 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-68.9 0-11.5 69.3c-2.9 17.4-19.4 29.2-36.8 26.3s-29.2-19.4-26.3-36.8l9.8-58.7-95.1 0-11.5 69.3c-2.9 17.4-19.4 29.2-36.8 26.3s-29.2-19.4-26.3-36.8L90.2 384 32 384c-17.7 0-32-14.3-32-32s14.3-32 32-32l68.9 0 21.3-128L64 192c-17.7 0-32-14.3-32-32s14.3-32 32-32l68.9 0 11.5-69.3c2.9-17.4 19.4-29.2 36.8-26.3zM187.1 192L165.8 320l95.1 0 21.3-128-95.1 0z"/></svg>`,
  } as const;

  private oldSearchString = '';
  private searchRequest?: Subscription;

  constructor(
    private searchService: SearchService,
    private navigationService: NavigationService,
    private router: Router,
    private domSanitizer: DomSanitizer,
  ) {
    this.searchService.shown$.subscribe((shown: boolean) => {
      this.shown = shown;

      if (this.shown) {
        instant(() => this.input?.nativeElement.focus()).then();
      } else {
        this.searchString = '';
        this.oldSearchString = '';
        this.searchResponse = undefined;
      }
    });
  }

  trackByFn: TrackByFunction<GeneratedSearchResult> = (_: number, res: GeneratedSearchResult) => {
    return this.searchResponse?.results.indexOf(res);
  };

  @HostListener('document:keydown', ['$event'])
  async onKeydown(event: KeyboardEvent) {
    switch (event.key) {
      case 'Escape': {
        if (this.shown) {
          event.preventDefault();
          this.searchService.hide();
        }
        break;
      }

      case 'k': {
        if (!event.ctrlKey) break;

        event.preventDefault();
        this.searchService.toggle();

        break;
      }

      case 'ArrowDown': {
        if (!this.shown) break;

        event.preventDefault();
        if (!this.searchResponse || !this.searchResponse.results) break;

        // this.viewport.

        const scrollToIndex = this.scrolledIndex >= this.searchResponse.results.length - 1 ? 0 : this.scrolledIndex + 1;
        this.viewport?.scrollToIndex(scrollToIndex);
        this.scrolledIndex = scrollToIndex;

        const uid = this.searchResponse.results[scrollToIndex].uid;
        const element = document.getElementById(`list-item-${uid}`);
        if (element instanceof HTMLElement) element.focus();

        break;
      }

      case 'ArrowUp': {
        if (!this.shown) break;

        event.preventDefault();
        if (!this.searchResponse || !this.searchResponse.results) break;

        const scrollToIndex = this.scrolledIndex <= 0 ? this.searchResponse.results.length - 1 : this.scrolledIndex - 1;
        this.viewport?.scrollToIndex(scrollToIndex);
        this.scrolledIndex = scrollToIndex;

        const uid = this.searchResponse.results[scrollToIndex].uid;
        const element = document.getElementById(`list-item-${uid}`);
        if (element instanceof HTMLElement) element.focus();

        break;
      }
    }
  }

  @HostListener('document:mousedown', ['$event'])
  async onDocumentClick(event: MouseEvent) {
    if (this.shown && event.target && !this.searchContainer.nativeElement.contains(event.target as HTMLElement)) {
      this.searchService.hide();
    }
  }

  async search() {
    if (this.searchString === this.oldSearchString) return;

    this.isLoading = true;
    this.searchResponse = undefined;

    const trimmedSearchString = this.searchString.trim();
    if (trimmedSearchString.length < 3) {
      this.isLoading = false;
      return;
    }

    this.oldSearchString = trimmedSearchString;

    this.searchRequest?.unsubscribe();
    this.searchRequest = this.searchService
      .search({
        query: trimmedSearchString,
      })
      .subscribe((r) => {
        this.searchResponse = r;
        this.scrolledIndex = -1;
        this.isLoading = false;
      });
  }

  async navigateToResult(result: GeneratedSearchResult) {
    switch (result.type) {
      case 'DATA_INSTANCE':
      case 'FIELD_VALUE': {
        await this.navigationService.navigateToDataInstance(result.uid);
        break;
      }
      case 'FILE':
        alert('Navigating to files is not implemented yet.');
        break;
      case 'VARIABLE':
        await this.router.navigate(['/home/variable/' + result.uid]);
        break;
    }

    return this.searchService.hide();
  }

  inputKeyDown(event: KeyboardEvent) {
    switch (event.key) {
      case 'Escape': {
        this.searchService.hide();
        event.preventDefault();
        return;
      }

      case 'Tab': {
        return;
      }

      case 'Enter': {
        this.debouncedSearch();
        return;
      }

      case 'Alt': {
        return;
      }
    }

    this.debouncedSearch();
  }

  getIcon(type: unknown) {
    return this.domSanitizer.bypassSecurityTrustHtml(this.iconLut[type as GeneratedSearchResult.TypeEnum]);
  }
}
