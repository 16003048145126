<div class="container">
  <button (click)="addStructInstance()" *ngIf="this.data && !this.value" class="btn btn-primary">Add</button>

  <div *ngIf="currentResource">
    <app-struct-instance-editor
      (deleteStructInstance)="deleteStructInstance.emit($event)"
      [data]="currentResource"
      [resourceStructType]="resourceStructType"
      [showDeleteButton]="showDeleteButton"
      [showHeader]="editorType !== 'SeamlessInline'"
      [showMedia]="showMedia"
      [titleOverride]="titleOverride"
    />
  </div>
</div>
