import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '@services/UI-elements/alert-service';
import { VariableType } from '@services/types/VariableType';
import { FieldEditorComponent } from '@services/dynamic-field.service';
import { Logger } from '@services/utils';
import { FieldType } from '@services/entities/helpers';
import { DataInstance, Variable } from '@services/entities';
import { VariableRepository } from '@services/repositories';

@Component({
  selector: 'app-set-variable-field',
  templateUrl: './set-variable-field.component.html',
  styleUrls: ['./set-variable-field.component.scss'],
})
export class SetVariableFieldComponent implements OnInit, FieldEditorComponent<string | number | boolean> {
  @Input({ required: true }) instance!: DataInstance;

  @Output() deleteSetVariable: EventEmitter<void> = new EventEmitter<void>();

  variables: Variable[] = [];
  variableUid = '';
  currentVariable?: Variable;

  value!: string | number | boolean;

  protected readonly String = String;
  protected readonly VariableType = VariableType;

  constructor(
    private router: Router,
    private alertService: AlertService,
    private variableRepository: VariableRepository,
  ) {}

  async ngOnInit() {
    if (!this.instance) throw new Error('Instance not found');

    const allVariables = await this.variableRepository.getAll();
    if (allVariables) this.variables = allVariables;

    if (!this.instance.fieldValues) {
      console.warn('No field values found on instance', this.instance);
      return;
    }

    this.variableUid = this.instance.fieldValues['variable']!.value as string;
    if (!this.variableUid) {
      Logger.warn(`No variable found on instance ${this.instance.__uid}`);
      return;
    }

    this.currentVariable = await this.variableRepository.get(this.variableUid);

    const valueField = this.instance.fieldValues['newValue']!;
    switch (this.currentVariable.valueType) {
      case VariableType.String:
        this.value = valueField.getDeserializedValue(FieldType.STRING, valueField.value);
        break;
      case VariableType.Number:
        this.value = valueField.getDeserializedValue(FieldType.INT, valueField.value);
        break;
      case VariableType.Boolean:
        this.value = valueField.getDeserializedValue(FieldType.BOOLEAN, valueField.value);
        break;
      case VariableType.Color:
        this.value = valueField.getDeserializedValue(FieldType.STRING, valueField.value);
        break;
      default:
        Logger.warn(`Unknown variable type: ${this.currentVariable.valueType}... Ignoring comparison operators`);
        break;
    }
  }

  async onVariableChange() {
    await this.instance.fieldValues['variable']!.set(this.variableUid);
    this.currentVariable = await this.variableRepository.get(this.variableUid);

    switch (this.currentVariable.valueType) {
      case VariableType.String:
        this.value = '';
        break;
      case VariableType.Number:
        this.value = 0;
        break;
      case VariableType.Boolean:
        this.value = false;
        break;
      case VariableType.Color:
        this.value = '#00000000';
        break;
    }

    await this.instance.fieldValues['newValue']!.set(String(this.value));
  }

  async onAddNewVariable() {
    if (!this.instance) throw new Error('Instance not found');

    const newVariable = await this.variableRepository.create({
      name: 'New Variable',
      variableRef: '_',
      valueType: VariableType.String,
      startValue: '',
      tags: [],
    });

    // We are not setting the fieldValue to the variable ref as it will be removed when you set the type of the variable
    // That removes all refernces to the variable including the one
    this.router.navigate(['/home/variable', newVariable.variableRef]).then();
  }

  onDelete() {
    this.deleteSetVariable.emit();
  }

  onViewStruct(variable: Variable | undefined) {
    if (!variable) throw new Error('Variable not found');
    this.router.navigate(['/home/variable/' + variable.variableRef]).then();
  }

  async update(value?: string) {
    try {
      if (value) this.value = String(value);
      await this.instance.fieldValues['newValue']!.set(String(this.value));
    } catch (e) {
      Logger.error(e);
      this.alertService.error(`Failed to save changes`);
    }
  }
}
