<div class="p-4 container-fluid">
  <div class="card shadow-sm">
    <div class="card-header">
      <h2>Datamodel editor</h2>
    </div>

    <div class="card-body d-flex flex-column gap-3">
      <button [routerLink]="'/home/maintenance'" class="btn btn-primary" style="width: fit-content">Go to maintenance page</button>

      <div class="flex flex-column">
        <div class="menu">
          <input (input)="debouncedSearch()" [(ngModel)]="searchString" class="form-control w-25" placeholder="Search" type="text" />

          <div class="d-flex gap-1">
            <button
              (click)="openModal(filterTablesToCopy)"
              [disabled]="selectedTables.length === 0"
              class="btn btn-secondary"
              title="Copy selected tables"
            >
              <i class="bi bi-copy"></i>
            </button>

            <button (click)="deleteTables()" [disabled]="selectedTables.length === 0" class="btn btn-danger" title="Delete selected tables">
              <i class="bi bi-trash"></i>
            </button>

            <div class="dropdown">
              <button
                aria-expanded="false"
                aria-haspopup="true"
                class="btn btn-primary dropdown-toggle"
                data-bs-toggle="dropdown"
                id="dropdownMenuButton"
                title="New"
                type="button"
              >
                <i class="bi bi-plus-lg"></i>
              </button>

              <div aria-labelledby="dropdownMenuButton" class="dropdown-menu">
                <button (click)="openModal(createModal)" class="dropdown-item" id="add-table-button">Table</button>
                <button (click)="openModal(createWithJSONModal, { size: 'xl' })" class="dropdown-item">Table(s) from clipboard</button>
              </div>
            </div>
          </div>
        </div>

        <div [ngStyle]="{ height: '30px' }" class="d-flex gap-1 mt-2 flex-wrap">
          <button (click)="filterNone(); activeSchema = schema.slice()" class="badge border-0 bg-primary">Remove filters</button>
          <button (click)="filterAll()" class="badge border-0 bg-primary">All tags</button>
          <button (click)="filterNone()" class="badge border-0 bg-primary">No tags</button>
          <div
            (click)="filterTable(tag)"
            *ngFor="let tag of tags"
            [ngStyle]="{
              'background-color': isTagUsedForFilter[tag.uid] ? tag.color : 'var(--bs-gray)',
              border: 'none',
              color: isTagUsedForFilter[tag.uid] ? tag.contrastColor : 'white',
            }"
            class="badge align-content-center ms-1 schema-tag cursor-pointer"
            role="none"
          >
            {{ tag.name }}
          </div>
        </div>
      </div>

      <div>
        <h5>{{ selectedTables.length }} selected</h5>

        <table class="table border table-hover">
          <thead>
            <tr>
              <th class="w-fit">
                <input
                  (change)="onSelectAllClick()"
                  [checked]="selectedTables.length === activeSchema.length"
                  class="form-check-input"
                  type="checkbox"
                />
              </th>

              <th>Name</th>
              <th>Description</th>
              <th>Tags</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let table of activeSchema">
              <td class="w-fit">
                <input
                  (change)="onCheckBoxClick(table)"
                  [checked]="selectedTables.includes(table)"
                  class="form-check-input"
                  type="checkbox"
                />
              </td>

              <td (click)="openTable($event, table)" class="cursor-pointer" id="{{ table.name }}-table-button">
                {{ table.name }}
              </td>

              <td (click)="openTable($event, table)" class="cursor-pointer">
                {{ table.description }}
              </td>

              <td class="text-center">
                <app-tags [allTags]="tags" [tagScope]="GeneratedTag.ScopeEnum.Model" [tags]="table.tags" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #createModal let-modal>
  <div class="modal-header">Create new</div>
  <div class="modal-body d-flex flex-column gap-3">
    <select #typeSelect class="form-select" id="table-type-selector">
      <option selected value="StructType">StructType</option>
      <option value="EnumType">EnumType</option>
      <option value="SelectType">SelectType</option>
    </select>

    <div>
      <div class="pb-1">Name</div>
      <input #nameInput class="form-control" id="name-of-table" type="text" />
    </div>

    <div class="modal-footer">
      <button (click)="modal.dismiss('Cross click')" class="btn btn-secondary">Cancel</button>
      <button (click)="createTable(typeSelect.value, nameInput.value)" class="btn btn-primary" id="create-table-button">Create</button>
    </div>
  </div>
</ng-template>

<ng-template #createWithJSONModal let-modal>
  <div class="p-3">
    <div class="modal-header">From clipboard</div>
    <div class="modal-body">
      <textarea
        #jsonArea
        class="form-control"
        placeholder="Paste your table(s)..."
        style="min-height: 50vh; font-family: Monaco, 'Lucida Console', monospace"
        type="text"
      ></textarea>
    </div>

    <div class="modal-footer">
      <button (click)="modal.dismiss('Cross click')" class="btn btn-secondary">Cancel</button>
      <button (click)="processClipboardData(jsonArea.value)" class="btn btn-primary">Save</button>
    </div>
  </div>
</ng-template>

<ng-template #filterTablesToCopy let-modal>
  <div class="p-3">
    <div class="modal-header">Filter selected tables</div>
    <div class="modal-body">
      Selecting tags will remove those tables/fields from the copy
      <button (click)="selectAllFilters()" class="badge border-0 bg-primary">Select all</button>
      <button (click)="selectNoFilters()" class="badge border-0 bg-primary">Remove selection</button>
      <div
        (click)="selectFilterForTableCopy(tag)"
        *ngFor="let tag of tags"
        [ngStyle]="{
          'background-color': isTagUsedForFilterTableCopy[tag.uid] ? tag.color : 'var(--bs-gray)',
          border: 'none',
          color: isTagUsedForFilterTableCopy[tag.uid] ? tag.contrastColor : 'white',
        }"
        class="badge align-content-center ms-1 schema-tag cursor-pointer"
        role="none"
      >
        {{ tag.name }}
      </div>
    </div>

    <div class="modal-footer">
      <button (click)="modal.dismiss('Cross click')" class="btn btn-secondary">Cancel</button>
      <button (click)="copyTables(selectedTables)" class="btn btn-primary">Copy</button>
    </div>
  </div>
</ng-template>
