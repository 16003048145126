import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { lastValueFrom, Subscription } from 'rxjs';
import { LoadingScreenService } from '@services/UI-elements/loading-screen.service';
import { FileEndpoints } from '@services/api';
import { GeneratedFileMeta, GeneratedTag } from '@services/types/generated';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Color, Logger } from '@services/utils';
import { Tag } from '@services/entities';
import { DataInstanceRepository, TagRepository } from '@services/repositories';
import { SearchService } from '@services/UI-elements/search.service';
import { NavigationService } from '@services/navigation.service';
import { Router } from '@angular/router';
import { ConfirmationModalService } from '@services/UI-elements/confirmation-modal.service';
import { HttpErrorResponse } from '@angular/common/http';
import { isGeneratedFileMeta, ListInstance } from '@services/utils/ListInstance';
import { AlertService } from '@services/UI-elements/alert-service';

@Component({
  selector: 'app-media-list',
  templateUrl: './media-list.component.html',
  styleUrls: ['./media-list.component.scss'],
})
export class MediaListComponent implements OnInit, OnDestroy {
  allMedia: GeneratedFileMeta[] = [];
  allTags: Tag[] = [];
  tagsPerMedia: Record<string, Tag[]> = {};
  loadingFiles = false;

  selectedMedia: GeneratedFileMeta | undefined;
  mediaUsages: Record<string, string>[] = [];
  mediaUsageParentUids: string[] = [];
  file?: File;
  uploadFileName = '';
  uploadFileAlt = '';

  protected readonly Color = Color;
  protected readonly Object = Object;
  private tagSubscription?: Subscription;

  constructor(
    private loadingScreenService: LoadingScreenService,
    private fileEndpoints: FileEndpoints,
    private modalService: NgbModal,
    private tagRepository: TagRepository,
    private searchService: SearchService,
    private dataInstanceRepository: DataInstanceRepository,
    private navigationService: NavigationService,
    private router: Router,
    private confirmationService: ConfirmationModalService,
    private alertService: AlertService,
  ) {}

  async ngOnInit() {
    await this.loadingScreenService.show(async () => {
      await this.loadMedia();
    });
  }

  ngOnDestroy() {
    this.tagSubscription?.unsubscribe();
  }

  async onUpdateFileName(event: { uid: string; name: string }) {
    const mediaToUpdate = this.allMedia.find((f) => f.uid === event.uid);
    if (!mediaToUpdate) return;
    mediaToUpdate.name = event.name;
    const newFileMeta = new FormData();
    newFileMeta.append('name', event.name);
    await lastValueFrom(this.fileEndpoints.updateFileMeta(mediaToUpdate.uid, newFileMeta));
  }

  async onUpdateFileAlt(event: { uid: string; alt: string }) {
    const mediaToUpdate = this.allMedia.find((f) => f.uid === event.uid);
    if (!mediaToUpdate) return;
    mediaToUpdate.alt = event.alt;
    const newFileMeta = new FormData();
    newFileMeta.append('alt', event.alt);
    await lastValueFrom(this.fileEndpoints.updateFileMeta(mediaToUpdate.uid, newFileMeta));
  }

  async onSubmitFileReplace(event: { uid: string; name: string; alt: string; file?: File }) {
    try {
      const newFileMeta = new FormData();
      newFileMeta.append('name', event.name);
      newFileMeta.append('alt', event.alt);
      if (event.file) newFileMeta.append('file', event.file);
      const newFile = await lastValueFrom(this.fileEndpoints.updateFileMeta(event.uid, newFileMeta));
      this.allMedia.splice(
        this.allMedia.findIndex((f) => f.uid === event.uid),
        1,
        newFile,
      );
      // force angular change detection
      this.allMedia = [...this.allMedia];
    } catch (error) {
      console.error(error);
    }
  }

  async onTagSelected(event: { tag: Tag; instanceUid: string }) {
    const serializedTag = await event.tag.serialize();
    const media = this.allMedia.find((m) => m.uid === event.instanceUid);
    if (!media) return;

    const tagUids = media.tags.map((t) => t.uid);
    if (tagUids.includes(serializedTag.uid)) {
      tagUids.splice(tagUids.indexOf(serializedTag.uid), 1);
    } else {
      tagUids.push(serializedTag.uid);
    }
    const formData = new FormData();
    formData.append('tags', JSON.stringify(tagUids));
    const newFile = await lastValueFrom(this.fileEndpoints.updateFileMeta(event.instanceUid, formData));
    this.allMedia.splice(
      this.allMedia.findIndex((f) => f.uid === event.instanceUid),
      1,
      newFile,
    );
    // force angular change detection
    this.allMedia = [...this.allMedia];
  }

  async onSelectMedia(modal: TemplateRef<unknown>, instance: ListInstance) {
    if (!isGeneratedFileMeta(instance)) return;

    this.selectedMedia = instance;
    this.mediaUsages = [];
    this.mediaUsageParentUids = [];

    await this.loadingScreenService.show(async () => {
      const mediaSearchResults = await lastValueFrom(this.searchService.search({ query: this.selectedMedia!.uid }));
      for (const r of mediaSearchResults.results) {
        const dataInstance = await this.dataInstanceRepository.get(r.uid);
        const [parent, traversedChildren] = await dataInstance.getParent();
        const mediaParents: Record<string, string> = {};
        let directParentUid = '';
        for (const child of traversedChildren) {
          if (directParentUid === '') directParentUid = await child.identifier;
          mediaParents[child.dataType] = child.getName();
        }
        mediaParents[parent.dataType] = parent.getName();
        this.mediaUsages.push(mediaParents);
        this.mediaUsageParentUids.push(directParentUid === '' ? await parent.identifier : directParentUid);
      }
    });

    this.modalService.open(modal, { ariaLabelledBy: 'media-usages-modal-title', size: 'lg' });
  }

  async onViewMediaUsage(index: number) {
    this.modalService.dismissAll();
    const parentUid = this.mediaUsageParentUids[index];
    const url = await this.navigationService.findDataInstanceUrl(parentUid);
    await this.router.navigate(url[0], url[1]);
  }

  async onDeleteMedia(instance: ListInstance) {
    if (!isGeneratedFileMeta(instance)) return;

    if (!(await lastValueFrom(this.confirmationService.confirm('Are you sure you want to delete this media?')))) return;

    try {
      await lastValueFrom(this.fileEndpoints.deleteFile(instance.uid));
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.error && error.error.status === 409) {
        if (
          !(await lastValueFrom(
            this.confirmationService.confirm(
              'This media is being used in other places, do you still want to delete this media and also delete all existing references to it?',
            ),
          ))
        )
          return;
        await lastValueFrom(this.fileEndpoints.deleteFile(instance.uid, true));
      }
    }

    this.allMedia = this.allMedia.filter((m) => m.uid !== instance.uid);
    // force angular change detection
    this.allMedia = [...this.allMedia];
  }

  async submitFileUpload(modal: NgbModalRef) {
    if (!this.file) {
      this.alertService.error('No file selected');
      return;
    }

    if (!this.uploadFileName) {
      window.alert('Please enter a name for the file');
      return;
    }

    const formData = new FormData();
    formData.append('file', this.file);
    formData.append('name', this.uploadFileName);
    formData.append('alt', this.uploadFileAlt);
    const newFile = await this.fileEndpoints.uploadFile(formData);
    this.allMedia.push(newFile);
    this.allMedia.sort((a, b) => a.name.localeCompare(b.name));
    this.tagsPerMedia[newFile.uid] = [];
    // force angular change detection
    this.allMedia = [...this.allMedia];

    modal.dismiss();

    this.file = undefined;
    this.uploadFileName = '';
    this.uploadFileAlt = '';
    return;
  }

  onFileUploadSelected(event: Event) {
    const target = event.target as HTMLInputElement;
    if (!target.files) return;

    this.file = target.files[0];
    if (!this.file) {
      Logger.error('No file selected');
      return;
    }
    this.uploadFileName = this.file.name;
    this.uploadFileAlt = '';
  }

  onOpenUploadModal(content: TemplateRef<unknown>): NgbModalRef {
    this.modalService.dismissAll('Closed before opening new modal');
    this.uploadFileName = '';
    this.uploadFileAlt = '';
    this.file = undefined;
    return this.modalService.open(content, { ariaLabelledBy: 'upload-modal-title', size: 'lg' });
  }

  async loadMedia() {
    this.loadingFiles = true;
    this.allMedia = await lastValueFrom(this.fileEndpoints.getAllFileMetas()).then((f) =>
      f.files.sort((a, b) => a.name.localeCompare(b.name)),
    );

    const tagDeserializationPromises = this.allMedia.map(async (media) => {
      media.tags.sort((a, b) => a.name.localeCompare(b.name));
      this.tagsPerMedia[media.uid] = await Promise.all(media.tags.map((tag) => Tag.deserialize(tag)));
    });
    await Promise.all(tagDeserializationPromises);

    this.allTags = await this.tagRepository.getAll();
    this.tagSubscription = this.tagRepository.cache$.subscribe((tags) => {
      this.allTags = tags.filter((tag) => tag.scope === GeneratedTag.ScopeEnum.Instance).sort((a, b) => a.name.localeCompare(b.name));
    });

    this.loadingFiles = false;
  }
}
