import { Repository } from './Repository';
import { NodePosition } from '@services/entities';
import { Injectable } from '@angular/core';
import { GeneratedNodePosition } from '../types/generated';
import { NodePositionEndpoints } from '../api';
import { BehaviorSubject, lastValueFrom, map, shareReplay } from 'rxjs';
import { Cache } from '../utils';
import { NodeCategory } from '../../models/types/NodeCategory';
import { DirtyHandling } from '@services/decorators/DirtyHandling';

@Injectable({ providedIn: 'root' })
export class NodePositionRepository extends Repository<NodePosition> {
  // TODO: I don't know if this is the best place for this behavior subject, but it works for now
  public readonly currentActivity = new BehaviorSubject<NodePosition | undefined>(undefined);
  public readonly currentActivity$ = this.currentActivity.asObservable();

  private readonly cache = new Cache<NodePosition>();

  constructor(private nodePositionEndpoints: NodePositionEndpoints) {
    super();
  }

  @DirtyHandling()
  public override async save(entity: NodePosition): Promise<void> {
    await lastValueFrom(this.nodePositionEndpoints.updateNodePosition(entity.dataInstanceUid, await entity.serialize()));
    this.cache.invalidate(entity.dataInstanceUid);
  }

  public override async delete(entity: NodePosition): Promise<void> {
    await lastValueFrom(this.nodePositionEndpoints.deleteNodePosition(entity.dataInstanceUid));
    this.cache.invalidate(entity.dataInstanceUid);
  }

  public override async create(data: GeneratedNodePosition, category: NodeCategory): Promise<NodePosition> {
    const nodePosition = await NodePosition.deserialize(
      await lastValueFrom(this.nodePositionEndpoints.updateNodePosition(data.dataInstanceUid, data)),
      category,
    );
    return this.cache.set(data.dataInstanceUid, nodePosition, 5);
  }

  public override async get(id: string, skipCache: boolean = false, category: NodeCategory): Promise<NodePosition> {
    if (!skipCache && this.cache.isValid(id)) {
      return this.cache.get(id)!.value;
    }

    if (this.requests[id] !== undefined) {
      return await lastValueFrom(this.requests[id]);
    }

    this.requests[id] = this.nodePositionEndpoints.getNodePosition(id).pipe(
      map(async (data) => {
        const nodePosition = await NodePosition.deserialize(data, category);
        return this.cache.set(nodePosition.dataInstanceUid, nodePosition, 5);
      }),
      shareReplay(1),
    );

    const data = await lastValueFrom(this.requests[id]);
    delete this.requests[id];
    return data;
  }
}
