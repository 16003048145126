<div class="container pb-2 mt-2">
  <div class="d-flex gap-1 mt-2 flex-wrap">
    <button (click)="clearFilters()" class="badge border-0 bg-primary">Remove filters</button>
    <button (click)="filterAll()" class="badge border-0 bg-primary">All tags</button>
    <button (click)="filterNone()" class="badge border-0 bg-primary">No tags</button>
    <div
      (click)="filterTable(tag)"
      *ngFor="let tag of allTags"
      [ngStyle]="{
        'background-color': isTagUsedForFilter[tag.uid] ? tag.color : 'var(--bs-gray)',
        border: 'none',
        color: isTagUsedForFilter[tag.uid] ? tag.contrastColor : 'white',
      }"
      class="badge align-content-center ms-1 schema-tag cursor-pointer"
      role="none"
    >
      {{ tag.name }}
    </div>
  </div>
  <div class="d-flex py-2">
    <input (ngModelChange)="debouncedSearch($event)" [(ngModel)]="searchTerm" class="form-control" placeholder="Search" type="text" />

    <button (click)="this.searchTerm = ''; search()" *ngIf="searchTerm.length" class="btn btn-secondary ms-2" type="button">
      <span class="bi bi-x"></span>
    </button>

    <button
      (click)="onShowAllMediaPreview()"
      *ngIf="isGeneratedFileMeta(allInstances[0])"
      class="btn btn-secondary ms-2"
      ngbPopover="Show the preview of all media."
      triggers="hover"
    >
      <span class="bi bi-eye"></span>
    </button>
    <button
      (click)="onHideAllMediaPreview()"
      *ngIf="isGeneratedFileMeta(allInstances[0])"
      class="btn btn-secondary ms-1"
      ngbPopover="Hide the preview of all media."
      triggers="hover"
    >
      <span class="bi bi-eye-slash"></span>
    </button>
    <button
      (click)="onPageChange(currentPage - 1)"
      [disabled]="currentPage === 1"
      class="btn btn-secondary ms-1"
      ngbPopover="Previous page"
      triggers="hover"
    >
      <span class="bi bi-arrow-left"></span>
    </button>
    <button
      (click)="onPageChange(currentPage + 1)"
      [disabled]="currentPage === maxPages"
      class="btn btn-secondary ms-1"
      ngbPopover="Next page"
      triggers="hover"
    >
      <span class="bi bi-arrow-right"></span>
    </button>
    <select
      (change)="updatePaginatedInstances()"
      [(ngModel)]="pageSize"
      class="form-select ms-1"
      id="paginated-size-select"
      style="max-width: 100px"
    >
      <option *ngFor="let size of ['25', '50', '75', '100', 'all']" [selected]="size === pageSize" [value]="size">{{ size }}</option>
    </select>
  </div>
  <table class="table table-striped table-hover">
    <thead>
      <tr>
        <!-- columns for media-list -->
        <th *ngIf="isGeneratedFileMeta(allInstances[0])" scope="col">Name</th>
        <th *ngIf="isGeneratedFileMeta(allInstances[0])" scope="col">Alt text</th>
        <th *ngIf="isGeneratedFileMeta(allInstances[0])" scope="col">Preview</th>

        <!-- columns for resource-list -->
        <th *ngFor="let field of fieldsToDisplay" scope="col">{{ field.name }}</th>

        <!-- columns for variable-list -->
        <th *ngIf="isVariable(allInstances[0])" scope="col">Name</th>
        <th *ngIf="isVariable(allInstances[0])" scope="col">Description</th>

        <!-- columns for all the lists -->
        <th scope="col">Tags</th>
        <th scope="col" style="width: 240px">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let instance of paginatedInstances">
        <!-- columns for media-list -->
        <td *ngIf="isGeneratedFileMeta(instance)">
          <div
            (click)="updateFileName = instance.name; fileMetaOperations[instance.uid].editingName = true"
            *ngIf="!fileMetaOperations[instance.uid].editingName"
            role="none"
          >
            {{ instance.name }}
          </div>
          <input
            (change)="onUpdateFileName(instance.uid)"
            *ngIf="fileMetaOperations[instance.uid].editingName"
            [(ngModel)]="updateFileName"
            class="form-control"
            style="max-width: 250px"
            type="text"
          />
        </td>
        <td *ngIf="isGeneratedFileMeta(instance)">
          <div
            (click)="updateFileAlt = instance.alt; fileMetaOperations[instance.uid].editingAlt = true"
            *ngIf="!fileMetaOperations[instance.uid].editingAlt"
            role="none"
          >
            {{ instance.alt }}
          </div>
          <input
            (change)="onUpdateFileAlt(instance.uid)"
            *ngIf="fileMetaOperations[instance.uid].editingAlt"
            [(ngModel)]="updateFileAlt"
            class="form-control"
            style="max-width: 250px"
            type="text"
          />
        </td>
        <td *ngIf="isGeneratedFileMeta(instance)">
          <img
            *ngIf="fileMetaOperations[instance.uid].showMedia && instance.fileType.includes('image')"
            [ngStyle]="{ 'max-height': '80px', 'max-width': '400px' }"
            [src]="instance.url"
            alt="image"
          />

          <audio
            *ngIf="fileMetaOperations[instance.uid].showMedia && instance.fileType.includes('audio')"
            controls
            src="{{ instance.url }}"
            style="width: 100%; max-width: 300px"
          ></audio>

          <div
            *ngIf="
              fileMetaOperations[instance.uid].showMedia && !instance.fileType.includes('image') && !instance.fileType.includes('audio')
            "
            class="text-muted"
          >
            No preview available.
          </div>
        </td>

        <!-- columns for variable-list -->
        <td (click)="onSelectInstance(instance)" *ngIf="isVariable(instance)" class="cursor-pointer">
          {{ instance.name }}
        </td>
        <td (click)="onSelectInstance(instance)" *ngIf="isVariable(instance)" class="cursor-pointer">
          {{ instance.description }}
        </td>

        <!-- columns for resource-list -->
        <td (click)="onSelectInstance(instance)" *ngFor="let field of fieldsToDisplay" class="cursor-pointer">
          {{ getFieldDisplayValue(instance, field) }}
        </td>

        <!-- columns for all the lists -->
        <td class="align-content-center">
          <app-tags
            (tagSelected)="onTagSelected($event, instance)"
            [allTags]="allTags"
            [tags]="tagsPerInstance[getUidOfListInstance(instance)]"
          />
        </td>
        <td class="align-content-center" style="width: 240px">
          <div class="d-flex gap-1 justify-content-end">
            <!-- buttons for the media-list -->
            <button
              (click)="fileMetaOperations[instance.uid].showMedia = !fileMetaOperations[instance.uid].showMedia"
              *ngIf="isGeneratedFileMeta(instance)"
              class="btn btn-no-space btn-outline-dark"
              ngbPopover="Show or hide a preview of the media."
              triggers="hover"
              type="button"
            >
              <span *ngIf="!fileMetaOperations[instance.uid].showMedia" class="bi bi-eye action-button"></span>
              <span *ngIf="fileMetaOperations[instance.uid].showMedia" class="bi bi-eye-slash action-button"></span>
            </button>
            <button
              (click)="onOpenReplaceModal(replaceModal, instance)"
              *ngIf="isGeneratedFileMeta(instance)"
              class="btn btn-no-space btn-outline-dark"
              ngbPopover="Replace or rename the file content. This will update it everywhere it is used."
              triggers="hover"
              type="button"
            >
              <span class="bi bi-recycle action-button"></span>
            </button>
            <button
              (click)="onSelectInstance(instance)"
              *ngIf="isGeneratedFileMeta(instance)"
              class="btn btn-no-space btn-outline-dark"
              ngbPopover="View the usages of this media."
              triggers="hover"
              type="button"
            >
              <span class="bi bi-search action-button"></span>
            </button>

            <!-- button for both resource- and variable-list -->
            <button
              (click)="onSelectInstance(instance)"
              *ngIf="isDataInstance(instance) || isVariable(instance)"
              class="btn btn-no-space btn-outline-dark align-right"
              id="open-instance"
              ngbPopover="Edit {{ getNameOfListInstance(instance) }}"
              triggers="hover"
            >
              <span class="bi bi-pencil-square action-button"></span>
            </button>

            <!-- buttons for the resource-list -->
            <button
              (click)="onDuplicateInstance(instance)"
              *ngIf="isDataInstance(instance)"
              class="btn btn-no-space btn-outline-dark"
              id="duplicate-instance"
              ngbPopover="Duplicate the instance."
              triggers="hover"
              type="button"
            >
              <span class="bi bi-copy action-button"></span>
            </button>
            <button
              (click)="onDownloadInstance(instance)"
              *ngIf="isDataInstance(instance)"
              class="btn btn-no-space btn-outline-dark align-right"
              ngbPopover="Download JSON"
              triggers="hover"
            >
              <i class="bi bi-download action-button"></i>
            </button>

            <!-- buttons for all the lists -->
            <button
              (click)="onCopyIdToClipboard(instance)"
              class="btn btn-no-space btn-outline-dark"
              ngbPopover="Copy the Uid of the instance to the clipboard."
              triggers="hover"
              type="button"
            >
              <span class="bi bi-code action-button"></span>
            </button>
            <button
              (click)="onDeleteInstance(instance)"
              class="btn btn-no-space btn-outline-dark"
              id="delete-instance"
              ngbPopover="Permanently delete the instance."
              triggers="hover"
              type="button"
            >
              <span class="bi bi-trash action-button"></span>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #replaceModal let-modal>
  <div class="modal-header" id="replace-file-modal-header">
    <h5 class="modal-title">Replace media</h5>
    <button
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
      class="btn-close"
      id="replace-file-modal-close-button"
      type="button"
    ></button>
  </div>
  <div class="modal-body">
    <form (submit)="onSubmitFileReplace(modal)">
      <div class="form-group">
        <input #fileUpload (change)="onFileUploadSelected($event)" class="hidden" id="fileUpload" type="file" />

        <div class="file-upload">
          {{ replaceFileName || 'No file uploaded yet.' }}

          <button
            (click)="fileUpload.click()"
            class="btn btn-primary btn-circle btn-xl"
            id="upload-file-from-local-storage-button"
            type="button"
          >
            <span class="bi bi-paperclip"></span>
          </button>
        </div>
      </div>
      <div *ngIf="replaceFileName" class="form-group">
        <label for="name">Name: </label>
        <input [(ngModel)]="replaceFileName" class="form-control" id="name" name="name" placeholder="Fill in name" type="text" />
      </div>
      <div *ngIf="replaceFileName" class="form-group">
        <label for="alt">Alternative: </label>
        <input [(ngModel)]="replaceFileAlt" class="form-control" id="alt" name="alt" placeholder="Fill in alternative" type="text" />
      </div>
      <button [disabled]="!replaceFileUid" class="btn btn-primary btn-space" type="submit">Submit</button>
    </form>
  </div>
</ng-template>
