import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingScreenService } from '@services/UI-elements/loading-screen.service';
import { DataInstance } from '@services/entities';
import { DataInstanceRepository } from '@services/repositories';

@Component({
  selector: 'app-map-editor',
  templateUrl: './map-editor.component.html',
  styleUrls: ['./map-editor.component.scss'],
})
export class MapEditorComponent implements OnInit {
  mapInstance?: DataInstance;

  constructor(
    private route: ActivatedRoute,
    private loadingScreenService: LoadingScreenService,
    private dataInstanceRepository: DataInstanceRepository,
  ) {}

  async ngOnInit() {
    await this.loadingScreenService.show(async () => {
      this.route.params.subscribe(async (params) => {
        const mapUid = params['mapUid'];
        if (!mapUid) return;

        this.mapInstance = await this.dataInstanceRepository.get(mapUid);
      });
    });
  }
}
