import { Repository } from './Repository';
import { DataInstance } from '@services/entities';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, map, shareReplay } from 'rxjs';
import { DataInstanceEndpoints } from '../api';
import { GeneratedDataInstance, GeneratedDuplicateDataInstanceRequest } from '../types/generated';
import { Cache, Pair, Try } from '../utils';
import GTInjector from '../GTInjector';
import { StructTypeRepository } from './StructTypeRepository';
import { FieldValue } from '@services/entities/helpers';
import { DirtyHandling } from '@services/decorators/DirtyHandling';

@Injectable({ providedIn: 'root' })
export class DataInstanceRepository extends Repository<DataInstance> {
  protected readonly entityUpdated = new BehaviorSubject<DataInstance | undefined>(undefined);

  /**
   * @deprecated Try to use `dataInstance.onChanges` instead. Note that that doesn't implement the exact same logic,
   * but it should be more performant.
   */
  public readonly entityUpdated$ = this.entityUpdated.asObservable();

  private readonly cache = new Cache<DataInstance>();

  constructor(private dataInstanceEndpoints: DataInstanceEndpoints) {
    super();
  }

  @DirtyHandling()
  public override async save(entity: DataInstance): Promise<void> {
    await lastValueFrom(this.dataInstanceEndpoints.updateDataInstance(undefined, await entity.serialize()));
    this.entityUpdated.next(entity);
  }

  public override async delete(entity: DataInstance, force = false): Promise<void> {
    await lastValueFrom(this.dataInstanceEndpoints.deleteDataInstances([await entity.identifier], force));
    this.cache.invalidate(await entity.identifier);
  }

  public override async create(structTypeId: string): Promise<DataInstance> {
    const structType = await Try(async () => await (await GTInjector.inject(StructTypeRepository)).get(structTypeId));
    if (structType === null) throw new Error(`Unable to find struct type ${structTypeId}`);

    const fieldValues: Record<string, FieldValue> = (
      await Promise.all(
        Object.values(structType.fields)
          .filter((field) => field.required)
          .map(
            async (field) =>
              await FieldValue.deserialize(
                {
                  field: field.fieldId,
                  value: await FieldValue.serializeValue(field.type, await field.getDefault()),
                },
                structType,
                '_',
              ),
          ),
      )
    )
      .filter(Boolean)
      .reduce(
        (acc, data) => {
          acc[data!.field.fieldId] = data!;
          return acc;
        },
        {} as Record<string, FieldValue>,
      );

    const data = {
      uid: '_',
      dataType: structTypeId,
      fieldValues: await Promise.all(Object.values(fieldValues).map((fv) => fv.serialize())),
      tags: [],
    } satisfies GeneratedDataInstance;

    const dataInstance = await DataInstance.deserialize(await lastValueFrom(this.dataInstanceEndpoints.createDataInstance(data)));
    this.entityUpdated.next(dataInstance);

    return this.cache.set(await dataInstance.identifier, dataInstance, 5);
  }

  public async getAllByStructTypeId(structTypeId: string): Promise<DataInstance[]> {
    return await Promise.all(
      (await lastValueFrom(this.dataInstanceEndpoints.getAllDataInstancesByStructTypeId(structTypeId))).map((di) =>
        this.cache.isValid(di.uid) ? this.cache.get(di.uid)!.value : DataInstance.deserialize(di),
      ),
    );
  }

  public async duplicateDataInstances(dataInstances: GeneratedDuplicateDataInstanceRequest): Promise<{
    dataInstances: DataInstance[];
    originalToDuplicateMapping: Record<string, string>;
  }> {
    const instances = await lastValueFrom(this.dataInstanceEndpoints.duplicateDataInstances(dataInstances));

    return {
      dataInstances: await Promise.all(
        instances.dataInstances.map(async (instance) => this.cache.set(instance.uid, await DataInstance.deserialize(instance), 5)),
      ),
      originalToDuplicateMapping: instances.originalToDuplicateMapping,
    };
  }

  public async deleteDataInstances(instances: (string | DataInstance)[], force: boolean = false) {
    const ids = await Promise.all(instances.map(async (i) => (i instanceof DataInstance ? await i.identifier : i)));
    await lastValueFrom(this.dataInstanceEndpoints.deleteDataInstances(ids, force));
    for (const id of ids) {
      this.cache.invalidate(id);
    }
  }

  public override async get(uid: string, skipCache: boolean = false): Promise<DataInstance> {
    if (!skipCache && this.cache.isValid(uid)) {
      return this.cache.get(uid)!.value;
    }

    if (this.requests[uid] !== undefined) {
      return await lastValueFrom(this.requests[uid]);
    }

    this.requests[uid] = this.dataInstanceEndpoints.getDataInstance(uid).pipe(
      map(async (response) => {
        const instances = [response, ...Object.values(response.subObjects ?? {})];
        await Promise.all(instances.map(async (instance) => this.cache.set(instance.uid, await DataInstance.deserialize(instance), 5)));
        return this.cache.get(uid)!.value;
      }),
      shareReplay(1),
    );

    const data = await lastValueFrom(this.requests[uid]);
    delete this.requests[uid];
    return data;
  }

  public async getParent(dataInstance: DataInstance | string, skipCache: boolean = false): Promise<Pair<DataInstance, DataInstance[]>> {
    const { parent, traversedChildren } = await lastValueFrom(
      this.dataInstanceEndpoints.getDataInstanceParent(dataInstance instanceof DataInstance ? await dataInstance.identifier : dataInstance),
    );

    const children = await Promise.all(
      traversedChildren.map(
        async (child) => this.cache.get(child.uid)?.value ?? this.cache.set(child.uid, await DataInstance.deserialize(child), 5),
      ),
    );

    if (!skipCache && this.cache.get(parent.uid)) {
      return [this.cache.get(parent.uid)!.value, children];
    }

    return [this.cache.set(parent.uid, await DataInstance.deserialize(parent), 5), children];
  }

  public async getDataInstanceWithChildWithDatatypeAndFieldValue(
    parentDatatype: string,
    currentDataType: string,
    fieldValue: string,
  ): Promise<DataInstance> {
    return await DataInstance.deserialize(
      await lastValueFrom(
        this.dataInstanceEndpoints.getDataInstanceWithChildWithDatatypeAndFieldValue(parentDatatype, currentDataType, fieldValue),
      ),
    );
  }

  /**
   * @deprecated Please don't use this...
   */
  public getCached() {
    return this.cache.getAll();
  }

  public isCached(uid: string) {
    return !!this.cache.get(uid);
  }

  public async persist(instance: DataInstance) {
    if (instance.isPersisted()) throw new Error('Cannot persist an already persisted entity');

    const data = {
      uid: '_',
      dataType: instance.dataType,
      fieldValues: await Promise.all(
        Object.values(instance.fieldValues)
          .filter(Boolean)
          .map((fv) => fv!.serialize()),
      ),
      tags: await Promise.all(instance.tags.map((tag) => tag.serialize())),
    } satisfies GeneratedDataInstance;

    const generated = await lastValueFrom(this.dataInstanceEndpoints.createDataInstance(data));
    instance.setUid(generated.uid);

    for (const fieldValue of Object.values(instance.fieldValues)) {
      if (!fieldValue) continue;
      fieldValue!.dataInstanceUid = generated.uid;
    }

    this.entityUpdated.next(instance);
  }
}
