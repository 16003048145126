<div class="mb-0">
  Type of <span class="fw-bold text-capitalize">{{ enumType | prettifyName }}</span
  >:
</div>

<select (change)="update()" *ngIf="data" [(ngModel)]="currentChoice" class="form-select mb-2" id="enum-type-selector" name="dropdown">
  <option *ngFor="let choice of choices" [value]="choice.value">{{ choice.name | prettifyName }}</option>
</select>

<app-variable-comparison-field
  *ngIf="data && currentChoiceDataInstance && currentChoice === 'VariableComparison'"
  [instance]="currentChoiceDataInstance"
/>

<app-set-variable-field
  *ngIf="data && currentChoiceDataInstance && currentChoice === 'SetVariableAction'"
  [instance]="currentChoiceDataInstance"
/>

<app-struct-instance-field-editor
  *ngIf="data && currentChoiceDataInstance && currentChoice !== 'VariableComparison' && currentChoice !== 'SetVariableAction'"
  [data]="data"
/>
