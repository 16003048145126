import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AlertService } from '@services/UI-elements/alert-service';
import { BootstrapClass } from '../../../../models/types/BootstrapClass';
import { instant } from '@services/utils';
import { Router } from '@angular/router';
import { LoadingScreenService } from '@services/UI-elements/loading-screen.service';
import { NavigationService } from '@services/navigation.service';
import { FieldEditorComponent } from '@services/dynamic-field.service';
import { FieldType, FieldTypes, FieldValue, SelectTypeOption } from '@services/entities/helpers';
import { DataInstanceRepository, StructTypeRepository } from '@services/repositories';

@Component({
  selector: 'app-selector-field',
  templateUrl: './selector-field.component.html',
  styleUrls: ['./selector-field.component.scss'],
})
export class SelectorFieldComponent implements OnInit, OnChanges, FieldEditorComponent<string | string[]> {
  @Input({ required: true }) data!: FieldValue;
  @Input() choices: SelectTypeOption[] = [];
  @Input() showReloadOptionButton = true;
  @Input() loading = false;

  @Output() selectionChanged = new EventEmitter<string | string[]>();
  @Output() reload = new EventEmitter<void>();

  value!: string[];
  isList!: boolean;

  structType? = '';

  constructor(
    private alertService: AlertService,
    private router: Router,
    private navigationService: NavigationService,
    protected loadingScreenService: LoadingScreenService,
    protected structTypeRepository: StructTypeRepository,
    protected dataInstanceRepository: DataInstanceRepository,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('data' in changes) {
      const deserialized = changes['data'].currentValue.getDeserializedValue(
        this.isList ? FieldType.LIST : FieldType.STRING,
        changes['data'].currentValue.value,
      ) as string[] | string;

      this.value = deserialized instanceof Array ? deserialized : [deserialized];
    }

    if ('choices' in changes) {
      this.updateChoices();
    }
  }

  async ngOnInit() {
    if (!this.data) return;

    this.isList = FieldTypes.isListType(this.data.field.type);

    const deserialized = this.data.getDeserializedValue(this.isList ? FieldType.LIST : FieldType.STRING, this.data.value) as
      | string[]
      | string;

    this.value = deserialized instanceof Array ? deserialized : [deserialized];

    // If the field is a list, set the isList flag to true
    const dataInstance = await this.dataInstanceRepository.get(this.data.dataInstanceUid);
    if (!dataInstance) throw new Error('Data instance not found');

    // TODO: extend to also support enum, which requires a dropdown to pick the enum option to create
    if (
      FieldTypes.matches('STRUCT_REF_MATCHER', this.data.field.type) ||
      // Wow, super mooie code hiero
      (() => {
        const listType = FieldTypes.getListType(this.data.field.type);
        return listType && FieldTypes.matches('STRUCT_REF_MATCHER', listType);
      })()
    ) {
      const dataType = FieldTypes.getDeepestReference(this.data.field.type);
      const allResources = (await this.structTypeRepository.getAllResources()).map((s) => s.typeId);

      if (dataType && allResources.includes(dataType)) {
        this.structType = dataType;
      }
    }

    // Add an empty choice to the front of the list, if it is not a list
    this.updateChoices();
  }

  // Add and remove are only used for lists
  addResourceSelector() {
    // If there are no choices, show warning alert
    if (this.choices.length < 1) {
      this.alertService.showAlert('No ' + this.data.field.name + ' found', BootstrapClass.WARNING);
      return;
    }
    this.value.push(this.choices[0].optionId);
    this.saveUpdates().then();
  }

  removeResourceSelector(indexToRemove: number) {
    this.value.splice(indexToRemove, 1);
    this.saveUpdates().then();
  }

  // Update the data instance when the dropdown is changed
  saveUpdates() {
    return instant(async () => {
      try {
        await this.data.set(this.isList ? this.value : this.value[0]);
        this.selectionChanged.emit(this.isList ? this.value : this.value[0]);
        return;
      } catch (e) {
        console.error(e);
      }
      return;
    });
  }

  async onViewStruct(index: number) {
    const instanceUid = this.value[index];
    const url = await this.navigationService.findDataInstanceUrl(instanceUid);
    await this.router.navigate(url[0], url[1]);
  }

  async onAddInstanceOfStruct(index: number) {
    if (!this.data || !this.structType) return;

    await this.loadingScreenService.show(async () => {
      try {
        const newStructInstance = await this.dataInstanceRepository.create(this.structType!);

        if (this.isList) {
          this.value.splice(index, 1, await newStructInstance.identifier);
          await this.data.set(this.value);
          await this.onViewStruct(index);
        } else {
          this.value = [await newStructInstance.identifier];
          await this.data.set(this.value[0]);
          await this.onViewStruct(0);
        }

        this.alertService.showAlert('Created new ' + this.structType + '...', BootstrapClass.SUCCESS);
      } catch (e) {
        this.alertService.showAlert('Failed to create new ' + this.structType + '...', BootstrapClass.DANGER);
        throw e;
      }
    });
  }

  reloadChoices() {
    this.reload.emit();
  }

  private updateChoices() {
    if (!this.isList && this.data.field && !this.choices.find((s) => s.optionId === '')) {
      const typeId = FieldTypes.getReferencedTypeId(this.data.field.type);
      this.choices = [
        new SelectTypeOption({
          optionId: '',
          label: 'Select ' + typeId,
        }),
        ...this.choices,
      ];
    }
  }
}
