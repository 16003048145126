<input
  (change)="this.update()"
  *ngIf="data"
  [(ngModel)]="value"
  class="form-control form-control-sm"
  id="{{ data.field.fieldId }}-number-input"
  max="{{ max }}"
  min="{{ min }}"
  placeholder="{{ data.field.description }}"
  type="number"
/>
