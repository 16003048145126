<div class="resource-title">
  <h1 [ngbPopover]="structTypeDescription" class="text-center text-white pb-2 drop-shadow" id="variable-header" triggers="hover">
    Variables
  </h1>
  <button (click)="createVariable()" class="btn btn-primary" id="create-variable-button"><i class="bi bi-plus-circle"></i></button>
</div>
<app-instances-list
  (deleteInstanceEmitter)="deleteVariable($event)"
  (selectInstanceEmitter)="openVariable($event)"
  (tagSelectedEmitter)="onTagSelected($event)"
  *ngIf="!loading && variables.length > 0"
  [allInstances]="variables"
  [allTags]="allTags"
  [tagsPerInstance]="tagsPerVariable"
/>
