import { Injectable } from '@angular/core';
import { ApiBase } from '../ApiBase';
import { Observable } from 'rxjs';
import { GeneratedFieldEditor } from '../../types/generated';

@Injectable({ providedIn: 'root' })
export class FieldEditorEndpoints extends ApiBase {
  public getFieldEditors(): Observable<GeneratedFieldEditor[]> {
    return this.get<GeneratedFieldEditor[]>(`/game/${this.gameId}/schema/field-editors`);
  }

  public createFieldEditor(fieldEditor: GeneratedFieldEditor): Observable<GeneratedFieldEditor> {
    return this.post<GeneratedFieldEditor>(`/game/${this.gameId}/schema/field-editors`, fieldEditor);
  }

  public updateFieldEditor(structTypeId: string, fieldEditor: GeneratedFieldEditor): Observable<GeneratedFieldEditor> {
    return this.put<GeneratedFieldEditor>(`/game/${this.gameId}/schema/field-editors/${structTypeId}/field-editors`, fieldEditor);
  }
}
