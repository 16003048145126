import { Component, Input, OnInit } from '@angular/core';
import { FieldEditorComponentType } from '@services/dynamic-field.service';
import { FieldValue } from '@services/entities/helpers';

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
})
export class FieldComponent implements OnInit {
  @Input({ required: true }) fieldComponent!: FieldEditorComponentType;
  @Input({ required: true }) data!: FieldValue;

  @Input() name = '';
  @Input() description = '';
  @Input() editorType: string | undefined;

  ngOnInit(): void {
    this.name = this.name || this.data?.field.name || '';
    this.description = this.data?.field.description || '';
    this.editorType = this.editorType || this.fieldComponent.editorType;
  }
}
