import { Directive, Input, OnChanges, SimpleChanges, ViewContainerRef } from '@angular/core';
import { FieldEditorComponentType } from '@services/dynamic-field.service';
import { FieldValue } from '@services/entities/helpers';

@Directive({
  selector: '[appField]',
})
export class FieldDirective implements OnChanges {
  @Input({ required: true }) appField!: FieldEditorComponentType;
  @Input({ required: true }) appData!: FieldValue;

  constructor(private viewRef: ViewContainerRef) {}

  ngOnChanges(_changes: SimpleChanges) {
    this.loadComponent();
  }

  loadComponent() {
    if (!this.appField) {
      throw new Error('Field is not set. Could not render component.');
    }

    if (this.appData == undefined) {
      throw new Error(`Data is not set. Could not render component. Field: ${this.appField.name}`);
    }

    this.viewRef.clear();

    const componentRef = this.viewRef.createComponent(this.appField);
    componentRef.setInput('data', this.appData);

    if ('editorType' in componentRef.instance) {
      componentRef.setInput('editorType', this.appField.editorType);
    }
  }
}
