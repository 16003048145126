import { Component, Injector } from '@angular/core';
import { SubcomponentService } from '@services/data-management/subcomponent.service';
import { Title } from '@angular/platform-browser';
import { LoadingScreenService } from '@services/UI-elements/loading-screen.service';
import { delay } from 'rxjs';
import GTInjector from '@services/GTInjector';
import { SavingIndicatorService } from '@services/UI-elements/saving-indicator.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [SubcomponentService],
})
export class AppComponent {
  showLoadingScreen = false;
  showSavingIndicator = false;

  constructor(
    loadingScreenService: LoadingScreenService,
    titleService: Title,
    savingIndicatorService: SavingIndicatorService,
    injector: Injector,
  ) {
    GTInjector.setInjector(injector);
    titleService.setTitle(`CAS`);

    // We use delay(0) to prevent ExpressionChangedAfterItHasBeenCheckedError
    loadingScreenService.shown$.pipe(delay(0)).subscribe((showSpinner: boolean) => (this.showLoadingScreen = showSpinner));

    savingIndicatorService.shown$
      .pipe(delay(0))
      .subscribe((showSavingIndicator: boolean) => (this.showSavingIndicator = showSavingIndicator));
  }
}
