import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '@services/UI-elements/alert-service';
import { VariableType } from '@services/types/VariableType';
import { CompareOperator } from '@services/types/CompareOperator';
import { DataInstance, Variable } from '@services/entities';
import { SelectTypeRepository, VariableRepository } from '@services/repositories';
import { Logger, Try } from '@services/utils';
import { FieldType, FieldValue, SelectTypeOption } from '@services/entities/helpers';
import { FieldEditorComponent } from '@services/dynamic-field.service';

@Component({
  selector: 'app-variable-comparison-field',
  templateUrl: './variable-comparison-field.component.html',
  styleUrls: ['./variable-comparison-field.component.scss'],
})
export class VariableComparisonFieldComponent implements OnInit, FieldEditorComponent<string | boolean | number> {
  @Input({ required: true }) instance!: DataInstance;
  @Input() showDelete = false;

  @Output() deleteVariableComparison: EventEmitter<void> = new EventEmitter<void>();

  variables1: Variable[] = [];
  variables2: Variable[] = [];

  currentVariable1?: Variable;
  currentVariable2?: Variable;

  variable1 = '';
  variable2 = '';

  allOperators: SelectTypeOption<CompareOperator>[] = [];
  operators: SelectTypeOption<CompareOperator>[] = [];
  operator = CompareOperator.EqualTo;

  compareWithVariable = false;

  value!: string | boolean | number;

  protected readonly String = String;
  protected readonly VariableType = VariableType;

  constructor(
    private router: Router,
    private alertService: AlertService,
    private variableRepository: VariableRepository,
    private selectTypeRepository: SelectTypeRepository,
  ) {}

  async ngOnInit() {
    if (!this.instance) throw new Error('No instance provided');

    const [variables, operators] = await Promise.all([
      this.variableRepository.getAll(),
      this.selectTypeRepository.get<CompareOperator>('VariableOperator'),
    ]);

    this.variables1 = variables;
    this.allOperators = operators.options;
    this.operator = (this.allOperators.find((operator) => operator.optionId === this.operator) ?? this.allOperators[0]).optionId;

    if (this.instance.fieldValues) {
      for (const field of Object.values(this.instance.fieldValues).filter(Boolean) as FieldValue[]) {
        // The value field cannot be set in here as it needs the variable1 field for its type
        switch (field.field.fieldId) {
          case 'variable1': {
            this.variable1 = field.value;
            this.currentVariable1 = this.variable1 ? await this.variableRepository.get(this.variable1) : undefined;
            break;
          }

          case 'variable2': {
            this.variable2 = field.value;
            break;
          }

          case 'operator': {
            if (!field.value) {
              this.operator = CompareOperator.EqualTo;
              break;
            }

            switch (field.value) {
              case CompareOperator.EqualTo:
              case CompareOperator.NotEqualTo:
              case CompareOperator.SmallerThan:
              case CompareOperator.GreaterThan:
              case CompareOperator.SmallerOrEqualTo:
              case CompareOperator.GreaterOrEqualTo: {
                this.operator = field.value;
                break;
              }
              default:
                this.operator =
                  Try(() => Object.values(CompareOperator).find((operator) => operator === field.value)) ?? CompareOperator.EqualTo;
                break;
            }
            break;
          }
        }
      }

      if (this.variable1) {
        if (!this.currentVariable1) {
          throw new Error('Variable not found');
        }

        switch (this.currentVariable1.valueType) {
          case VariableType.String: {
            this.operators = this.allOperators.filter((operator) =>
              [CompareOperator.EqualTo, CompareOperator.NotEqualTo].includes(operator.optionId),
            );
            const valueField = this.instance.fieldValues['value']!;
            this.value = valueField.getDeserializedValue(FieldType.STRING, valueField.value);
            break;
          }
          case VariableType.Number: {
            this.operators = this.allOperators.filter((operator) =>
              [
                CompareOperator.EqualTo,
                CompareOperator.NotEqualTo,
                CompareOperator.SmallerThan,
                CompareOperator.GreaterThan,
                CompareOperator.SmallerOrEqualTo,
                CompareOperator.GreaterOrEqualTo,
              ].includes(operator.optionId),
            );
            const valueField = this.instance.fieldValues['value']!;
            this.value = valueField.getDeserializedValue(FieldType.INT, valueField.value);
            break;
          }
          case VariableType.Boolean: {
            this.operators = this.allOperators.filter((operator) =>
              [CompareOperator.EqualTo, CompareOperator.NotEqualTo].includes(operator.optionId),
            );
            const valueField = this.instance.fieldValues['value']!;
            this.value = valueField.getDeserializedValue(FieldType.BOOLEAN, valueField.value);
            break;
          }
          case VariableType.Color: {
            this.operators = this.allOperators.filter((operator) =>
              [CompareOperator.EqualTo, CompareOperator.NotEqualTo].includes(operator.optionId),
            );
            const valueField = this.instance.fieldValues['value']!;
            this.value = valueField.getDeserializedValue(FieldType.STRING, valueField.value);
            break;
          }
          default:
            Logger.warn(`Unknown variable type: ${this.currentVariable1.valueType}... Ignoring comparison operators`);
            break;
        }

        this.variables2 = variables.filter((variable) => variable.valueType === this.currentVariable1?.valueType);
      }

      // For backwards compatibility
      if (this.variable2 === 'CompareVariableWithValue') {
        this.variable2 = '';
        await this.instance.fieldValues['variable2']!.set('');
      }

      if (this.variable2) {
        this.currentVariable2 = await this.variableRepository.get(this.variable2);
        this.compareWithVariable = true;
        if (!this.currentVariable2) throw new Error('Variable not found');
      }
    }
  }

  async onVariableChange(fieldName: string) {
    if (!this.instance) {
      throw new Error('Instance not found');
    }

    const field = this.instance.fieldValues[fieldName];
    if (!field) {
      throw new Error('Field not found');
    }

    switch (field.field.fieldId) {
      case 'variable1': {
        const newVariable = await this.variableRepository.get(this.variable1);
        await field.set(this.variable1);

        if (newVariable.valueType !== this.currentVariable1?.valueType) {
          switch (newVariable.valueType) {
            case VariableType.String:
              this.operators = this.allOperators.filter((operator) =>
                [CompareOperator.EqualTo, CompareOperator.NotEqualTo].includes(operator.optionId as CompareOperator),
              );
              break;
            case VariableType.Number:
              this.operators = this.allOperators.filter((operator) =>
                [
                  CompareOperator.EqualTo,
                  CompareOperator.NotEqualTo,
                  CompareOperator.SmallerThan,
                  CompareOperator.GreaterThan,
                  CompareOperator.SmallerOrEqualTo,
                  CompareOperator.GreaterOrEqualTo,
                ].includes(operator.optionId),
              );
              break;
            case VariableType.Boolean:
            case VariableType.Color:
              this.operators = this.allOperators.filter((operator) =>
                [CompareOperator.EqualTo, CompareOperator.NotEqualTo].includes(operator.optionId),
              );
              break;
            default:
              // Handle default case if needed
              break;
          }

          this.variables2 = this.variables1.filter((variable) => variable.valueType === newVariable.valueType);

          if (this.compareWithVariable) {
            this.variable2 = '';
            await this.instance.fieldValues['variable2']!.set('');
          } else {
            await this.setDefault(newVariable.valueType!);
          }

          this.operator = CompareOperator.EqualTo;
          await this.instance.fieldValues['operator']!.set(this.operator);
        }

        this.currentVariable1 = newVariable;
        break;
      }

      case 'variable2': {
        await field.set(this.variable2);
        this.currentVariable2 = await this.variableRepository.get(this.variable2);
        break;
      }

      case 'operator': {
        await field.set(this.operator satisfies CompareOperator);
        break;
      }

      default: {
        throw new Error(`Unknown field '${field}'`);
      }
    }
  }

  async onSwitchCompareWithVariable() {
    if (!this.instance) throw new Error('Instance not found');

    if (this.compareWithVariable) {
      this.variable2 = '';
      this.compareWithVariable = false;
      await this.instance.fieldValues['variable2']!.set('');
    } else {
      this.value = '';
      this.compareWithVariable = true;
      await this.instance.fieldValues['value']!.set(this.value satisfies string);
    }
  }

  onViewStruct(variable: Variable | undefined) {
    if (!variable) throw new Error('Variable not found');
    this.router.navigate(['/home/variable/' + variable.variableRef]).then();
  }

  async onAddNewVariable() {
    if (!this.instance) throw new Error('Instance not found');

    const newVariable = await this.variableRepository.create({
      name: 'New Variable',
      variableRef: '_',
      valueType: VariableType.String,
      startValue: '',
      tags: [],
    });

    // We are not setting the fieldValue to the variable ref as it will be removed when you set the type of the variable
    // That removes all refernces to the variable including the one
    this.router.navigate(['/home/variable', newVariable.variableRef]).then();
  }

  onDelete() {
    this.deleteVariableComparison.emit();
  }

  async update(value?: string) {
    try {
      if (value) this.value = String(value);

      await Promise.all([
        this.instance.fieldValues['value']!.set(String(this.value) satisfies string),
        this.instance.fieldValues['operator']!.set(this.operator satisfies CompareOperator),
        this.instance.fieldValues['variable1']!.set(this.variable1),
        this.instance.fieldValues['variable2']!.set(this.variable2),
      ]);
    } catch (e) {
      Logger.error(e);
      this.alertService.error(`Failed to save changes`);
    }
  }

  private async setDefault(variableType: VariableType) {
    const fieldValue = this.instance.fieldValues['value']!;

    const set = (value: string | number | boolean) => {
      this.value = value;
      return fieldValue.set(String(this.value) satisfies string);
    };

    if (fieldValue.field.defaultValue) {
      return await set(fieldValue.field.defaultValue);
    }

    switch (variableType) {
      case VariableType.String: {
        return await set('');
      }

      case VariableType.Number: {
        return await set(0);
      }

      case VariableType.Boolean: {
        return await set(false);
      }

      case VariableType.Color: {
        return await set('#00000000');
      }
    }
  }
}
