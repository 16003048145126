import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SearchService } from '@services/UI-elements/search.service';
import { MiscEndpoints } from '@services/api';
import { ResourceService } from '@services/resource.service';

interface NavItem {
  name: string;
  route: string;
}

@Component({
  selector: 'app-home-screen',
  templateUrl: './home-screen.component.html',
  styleUrls: ['./home-screen.component.scss'],
})
export class HomeScreenComponent {
  /*
   * The home screen component is the first component that is loaded when the app is opened (unless you are not logged in).
   * It only holds the navigation bar where you can access the overview list of all resources.
   * Via routing the correct list and editor components are loaded.
   */

  protected currentTheme: 'dark' | 'light' = 'light';
  protected resourceStructsPromise: Promise<NavItem[]>;
  protected readonly versions = [
    `Frontend : Build#${environment.version_latestBuildNumber || '0'} [${(environment.version_latestCommitHash || 'abcdefg').slice(0, 7)}] - ${environment.version_latestDate || 'Unknown'}`,
  ];

  constructor(
    protected searchService: SearchService,
    private resourceService: ResourceService,
    miscEndpoints: MiscEndpoints,
  ) {
    resourceService.onUpdated$.subscribe((structs) => (this.resourceStructsPromise = this.loadResourceNavItems(structs)));
    this.resourceStructsPromise = this.loadResourceNavItems();

    this.currentTheme = (localStorage.getItem('theme') ??
      (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')) as 'dark' | 'light';

    //Set the theme based on the user's system preference
    document.documentElement.setAttribute('data-bs-theme', this.currentTheme);

    miscEndpoints.getVersion().subscribe((data) => {
      this.versions.push(
        `Backend  : ${data.version.tag.replace('-SNAPSHOT', '')}#${data.version.buildNumber} [${data.version.commitHash.slice(0, 7)}] - ${data.version.timestamp}`,
      );
    });
  }

  showSearch() {
    this.searchService.show();
  }

  toggleDarkMode() {
    const newTheme = this.currentTheme === 'dark' ? 'light' : 'dark';
    document.documentElement.setAttribute('data-bs-theme', newTheme);
    localStorage.setItem('theme', newTheme);
    this.currentTheme = newTheme;
  }

  private async loadResourceNavItems(structs?: typeof this.resourceService.resourceStructs): Promise<NavItem[]> {
    if (!structs) await this.resourceService.waitForInit();

    return [
      ...Object.values(structs ?? this.resourceService.resourceStructs).map((i) => ({
        name: i.name,
        route: i.typeId,
      })),
      {
        name: 'Variables',
        route: 'Variable',
      },
      {
        name: 'Media',
        route: 'Media',
      },
    ].sort((a, b) => a.name.localeCompare(b.name));
  }
}
