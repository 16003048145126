import { Entity } from './Entity';
import { AutoSave } from '../decorators/AutoSave';
import { GeneratedEnumType } from '../types/generated';
import GTInjector from '../GTInjector';
import { EnumTypeRepository } from '@services/repositories';
import { Tag } from '@services/entities';

export interface NewEnumType {
  typeId: string;
  name: string;
  description?: string;
  options: string[];
  tags: Tag[];
}

@AutoSave()
export class EnumType extends Entity {
  public readonly typeId: string;

  public name: string;
  public description?: string;
  public options: string[];
  public tags: Tag[];

  constructor({ typeId, name, description, options, tags }: NewEnumType) {
    super();
    this.typeId = typeId;
    this.name = name;
    this.description = description;
    this.options = options;
    this.tags = tags;
  }

  public override get identifier() {
    return this.typeId;
  }

  public static async deserialize(data: GeneratedEnumType): Promise<EnumType> {
    const enumType = new EnumType({
      typeId: data.typeId,
      name: data.name,
      description: data.description,
      options: data.options,
      tags: await Promise.all(data.tags.map((tag) => Tag.deserialize(tag))),
    });
    await enumType.updateLastSavedValueHash();
    return enumType;
  }

  /**
   * @deprecated This method should be rarely used by a developer because @AutoSave() should handle most cases. If you
   * find any cases where auto saving does not work, please open a bug report so it can be fixed.
   */
  public async save() {
    return (await GTInjector.inject(EnumTypeRepository)).save(this);
  }

  public async serialize(): Promise<Readonly<GeneratedEnumType>> {
    return Object.freeze({
      typeId: this.typeId,
      name: this.name,
      description: this.description,
      options: this.options,
      tags: await Promise.all(this.tags.map((tag) => tag.serialize())),
    });
  }
}
