<ng-container *ngTemplateOutlet="editorType === 'Seamless' ? seamlessInlineTemplate : editorType === 'Hidden' ? hiddenTemplate : template">
</ng-container>

<ng-template #seamlessInlineTemplate>
  <ng-container [appData]="data" [appField]="fieldComponent"></ng-container>
  <ng-content></ng-content>
</ng-template>

<ng-template #hiddenTemplate>
  <div></div>
</ng-template>

<ng-template #template>
  <div class="mode-container">
    <div class="input-group" id="{{ data.field.fieldId }}-field">
      <div id="{{ data.field.fieldId }}-field-label">
        <div [ngbPopover]="description" class="mode-label-above px-2 pt-1 h-100" triggers="hover">{{ name }}:</div>
        <div [ngbPopover]="description" class="mode-normal input-group-prepend h-100" triggers="hover">
          <span class="input-group-text">{{ name }}</span>
        </div>
      </div>

      <div class="form-control">
        <ng-container [appData]="data" [appField]="fieldComponent"></ng-container>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</ng-template>
