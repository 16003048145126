import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FieldType, FieldTypes, FieldValue } from '@services/entities/helpers';
import { FieldEditorComponent } from '@services/dynamic-field.service';
import { DataInstanceRepository } from '@services/repositories';
import { DataInstance } from '@services/entities';

@Component({
  selector: 'app-struct-instance-field-editor',
  templateUrl: './struct-instance-field-editor.component.html',
  styleUrl: './struct-instance-field-editor.component.scss',
})
export class StructInstanceFieldEditorComponent implements OnInit, FieldEditorComponent<string> {
  @Input({ required: true }) data!: FieldValue;
  @Input() editorType: string | undefined;
  @Input() titleOverride?: string;
  @Input() resourceStructType?: string;
  @Input() showDeleteButton = false;
  @Input() showMedia = true;

  @Output() deleteStructInstance: EventEmitter<void> = new EventEmitter<void>();

  value!: string;

  currentResource?: DataInstance;
  currentResourceName?: string;

  constructor(private dataInstanceRepository: DataInstanceRepository) {}

  async ngOnInit() {
    if (!this.data) throw new Error('Data not found!');

    const handle = async () => {
      if (!this.data) throw new Error('Data not found!');

      this.value = this.data.getDeserializedValue(FieldType.STRING, this.data.value);
      this.editorType = this.editorType || this.data.field.fieldEditor?.editorType;

      if (this.value) {
        this.currentResource = await this.dataInstanceRepository.get(this.value);
        this.resourceStructType ??= this.currentResource.dataType;
        this.currentResourceName = this.titleOverride ?? this.currentResource.getName();
      }
    };

    this.data.onChange.subscribe(handle.bind(this));
    await handle();
  }

  async addStructInstance() {
    if (!this.data) throw new Error('Data not found!');

    const deepestReference = FieldTypes.getDeepestReference(this.data.field.type);
    if (!deepestReference) throw new Error('Deepest reference not found'!);

    const dataInstance = await this.dataInstanceRepository.create(deepestReference);
    await this.data.set(dataInstance);

    this.currentResource = dataInstance;
    this.resourceStructType = dataInstance.dataType;

    this.currentResourceName = this.titleOverride ?? this.currentResource.getName();
  }
}
