<div *ngIf="data" class="form-check form-switch">
  <input
    (change)="update()"
    [(ngModel)]="value"
    class="form-check-input"
    id="{{ data.field.fieldId }}-checkbox"
    role="switch"
    type="checkbox"
  />
</div>
