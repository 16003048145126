import { Component, Input, OnInit } from '@angular/core';
import { SimpleStructInstance, SubcomponentService } from '@services/data-management/subcomponent.service';
import { FieldEditorComponent } from '@services/dynamic-field.service';
import { FieldType, FieldTypes, FieldValue, SelectTypeOption } from '@services/entities/helpers';
import { SelectTypeRepository } from '@services/repositories/SelectTypeRepository';
import { SelectType } from '@services/entities';
import { DataInstanceRepository } from '@services/repositories/DataInstanceRepository';
import { StructTypeRepository } from '@services/repositories/StructTypeRepository';

@Component({
  selector: 'app-chat-field',
  templateUrl: './chat-field.component.html',
  styleUrls: ['./chat-field.component.scss'],
})
export class ChatFieldComponent implements OnInit, FieldEditorComponent<string> {
  @Input({ required: true }) data!: FieldValue;

  value!: string;

  chatCharacters: SelectTypeOption[] = [];
  messages: SimpleStructInstance[] = [];
  mediaUrls: Record<string, string> = {};
  hasEmotionField: boolean = true;

  selectType?: SelectType;

  protected readonly FieldType = FieldType;
  protected readonly FieldTypes = FieldTypes;

  constructor(
    private subCompService: SubcomponentService,
    private selectTypeRepository: SelectTypeRepository,
    private dataInstanceRepository: DataInstanceRepository,
    private structTypeRepository: StructTypeRepository,
  ) {}

  async ngOnInit() {
    this.value = this.data.getDeserializedValue(FieldType.STRING, this.data.value);
    this.selectType = await this.selectTypeRepository.get('ChatCharacterType');
    this.chatCharacters = this.selectType!.options;

    if (this.data && this.data.value) {
      this.messages = await this.subCompService.convertArrayData(this.data);
    }

    this.hasEmotionField = (await this.structTypeRepository.get('TextMessage')).hasField('emotion');
  }

  async addMessage(characterName: string, messageTypeId: string) {
    const messageStruct = await this.structTypeRepository.get(messageTypeId);
    if (!this.data) throw new Error('Data not found');

    const message = await this.subCompService.addSubStruct(messageStruct);
    this.messages.push(message);

    await this.data.set([...(this.data.getDeserializedValue(FieldType.LIST, this.data.value) as string[]), message.uid]);

    // TODO: The dropdown is initialized with the first value, but the value is not set in the data instance
    //  That's why for now we copy the field, delete it, set the value and then add it again.
    const messageField = this.messages[this.messages.length - 1];

    this.messages.splice(this.messages.length - 1, 1);
    await this.changeMessageCharacter(messageField, characterName);
    this.messages.push(messageField);
  }

  async changeMessageCharacter(messageField: SimpleStructInstance, characterName: string) {
    // Get the character resource
    const character = this.chatCharacters.find((character) => character.label === characterName);
    if (!character) throw new Error('Character not found');

    const dataInstance = await this.dataInstanceRepository.get(messageField.uid);
    await dataInstance.fieldValues['character']!.set(character.optionId);
  }

  changeImageOfMessage(imageUrl: { name: string; url: string }, messageUid: string) {
    this.mediaUrls[messageUid] = imageUrl.url;
  }

  isLastMessage(message: SimpleStructInstance) {
    return this.subCompService.isLastField(message, this.messages);
  }

  isFirstMessage(message: SimpleStructInstance) {
    return this.subCompService.isFirstField(message, this.messages);
  }

  async moveMessageUp(message: SimpleStructInstance) {
    if (!this.data) throw new Error('Data not found');
    const dataInstance = await this.dataInstanceRepository.get(this.data.dataInstanceUid);
    await this.subCompService.moveFieldUp(message, this.messages, dataInstance, this.data.field.fieldId);
  }

  async moveMessageDown(message: SimpleStructInstance) {
    if (!this.data) throw new Error('Data not found');
    const dataInstance = await this.dataInstanceRepository.get(this.data.dataInstanceUid);
    await this.subCompService.moveFieldDown(message, this.messages, dataInstance, this.data.field.fieldId);
  }

  async removeMessage(message: SimpleStructInstance) {
    if (!this.data) throw new Error('Data not found');
    const dataInstance = await this.dataInstanceRepository.get(this.data.dataInstanceUid);
    await this.subCompService.removeSubStruct(message, this.messages, dataInstance, this.data.field.fieldId);

    const value = this.data.getDeserializedValue(FieldType.LIST, this.data.value) as string[];
    await this.data.set(value.filter((reference) => reference !== message.uid));
  }

  isSentByPlayer(message: SimpleStructInstance) {
    return message.fields['character'].value === this.chatCharacters.find((character) => character.label === 'Player')?.optionId;
  }

  isSentByNPC(message: SimpleStructInstance) {
    return message.fields['character'].value === this.chatCharacters.find((character) => character.label === 'NPC')?.optionId;
  }

  isSentByGuide(message: SimpleStructInstance) {
    return message.fields['character'].value === this.chatCharacters.find((character) => character.label === 'Guide')?.optionId;
  }
}
