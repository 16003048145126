import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { generateRandomString, Logger, sleep, Vector2 } from '@services/utils';
import { FieldType, FieldValue } from '@services/entities/helpers';
import { LoadingScreenService } from '@services/UI-elements/loading-screen.service';
import { DataInstanceRepository, NodePositionRepository } from '@services/repositories';
import { NodeCategory } from '../../../../models/types/NodeCategory';
import { DataInstance, NodePosition } from '@services/entities';
import { FlowchartType } from '../../flowchart/flowchart.component';

@Component({
  selector: 'app-mission-editor',
  templateUrl: './mission-editor.component.html',
  styleUrls: ['./mission-editor.component.scss'],
})
export class MissionEditorComponent implements OnInit, OnDestroy {
  @Output() goToHomePageEvent = new EventEmitter<void>();

  missionInfoUid?: string;
  missionInfoFieldData?: FieldValue;
  moduleId?: string;
  activityInstanceUid?: string;
  loadingMission = false;
  loadingActivity = false;

  missionData?: DataInstance;
  missionInfo?: DataInstance;
  missionInfoIdentifier?: string;

  missionName?: string;
  currentActivity?: NodePosition = undefined;
  startActivityField?: FieldValue;
  routerSubscription?: Subscription;
  activitiesSubscription?: Subscription;
  saveButtonSubscription?: Subscription;
  currentActivitySubscription?: Subscription;

  nodes: NodePosition[] = [];

  protected readonly environment = environment;
  protected readonly FlowchartType = FlowchartType;
  private routeParamsSub?: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loadingScreenService: LoadingScreenService,
    private dataInstanceRepository: DataInstanceRepository,
    private nodePositionRepository: NodePositionRepository,
  ) {}

  async setSelectedNode(node: NodePosition | undefined) {
    if (this.loadingMission) return;
    return await this.router.navigate([], { queryParams: { activityNode: node ? node.dataInstanceUid : null } });
  }

  async ngOnInit() {
    this.routeParamsSub = this.activatedRoute.params.subscribe(async (params) => {
      this.missionInfoUid = params['missionInfoUid'];
      this.missionInfoFieldData = undefined;
      this.loadingMission = true;

      await this.loadMission();

      this.loadingMission = false;
    });

    this.routerSubscription = this.activatedRoute.queryParams.subscribe(async (params) => {
      this.activityInstanceUid = params['activityNode'];
      this.loadingActivity = true;

      // Wait for the loading of the mission to finish
      while (this.loadingMission) {
        await sleep(100);
      }

      this.currentActivity = this.nodes.find((a) => a.dataInstanceUid === this.activityInstanceUid);
      this.nodePositionRepository.currentActivity.next(this.activityInstanceUid ? this.currentActivity : undefined);

      this.loadingActivity = false;
    });
  }

  ngOnDestroy() {
    this.activitiesSubscription?.unsubscribe();
    this.currentActivitySubscription?.unsubscribe();
    this.saveButtonSubscription?.unsubscribe();
    this.routeParamsSub?.unsubscribe();
    this.routerSubscription?.unsubscribe();
  }

  loadMission() {
    return this.loadingScreenService.show(async () => {
      if (!this.missionInfoUid) return Logger.error('No missionInfoUid provided');
      Logger.debug(`Attempting to load mission: ${this.missionInfoUid}`);

      this.missionInfo = await this._loadMission(this.missionInfoUid);
      this.missionInfoIdentifier = await this.missionInfo.identifier;

      const missionUid = this.tryGetMissionUid();
      this.missionName = this.missionInfo?.fieldValues['name']?.value as string;

      if (missionUid) {
        this.missionData = await this.dataInstanceRepository.get(missionUid);

        const startActivityFieldValue = this.missionData.fieldValues['startActivity'];
        if (!startActivityFieldValue) throw new Error('Start activity not found');

        this.startActivityField = startActivityFieldValue;
        try {
          const module = await this.dataInstanceRepository.getDataInstanceWithChildWithDatatypeAndFieldValue(
            'Module',
            'List<StructRef<MissionInfo>>',
            this.missionInfoUid,
          );

          if (module) this.moduleId = module.fieldValues['moduleId']?.value as string;
        } catch (e) {
          Logger.warn('Could not find module for mission', e);
        }
      }
    });
  }

  // onAddActivity without name
  onAddActivity(activityTypeId: string) {
    return this.loadingScreenService.show(async () => {
      const newActivity = await this.dataInstanceRepository.create(activityTypeId);
      await newActivity.setFieldValue('name', newActivity.dataType + ' ' + generateRandomString(10));

      // Update the activities fieldValue of the current mission
      if (!this.missionInfo) return;

      const missionUid = this.tryGetMissionUid();
      if (!missionUid) return;

      this.missionData = await this.dataInstanceRepository.get(missionUid);

      if (this.missionData) {
        const activitiesField = this.missionData.fieldValues['activities']!;
        await activitiesField.set([
          ...activitiesField.getDeserializedValue(FieldType.LIST, activitiesField.value),
          await newActivity.identifier,
        ]);
        await this._loadMission(await this.missionInfo.identifier);
      }
    });
  }

  async deleteSelectedNodes(activityInstanceUids: string[]) {
    await this.loadingScreenService.show(async () => {
      if (this.missionData) {
        const activitiesField = this.missionData.fieldValues['activities']!;
        await activitiesField.set([
          ...activitiesField
            .getDeserializedValue(FieldType.LIST, activitiesField.value)
            .filter((uid) => !activityInstanceUids.includes(uid as string)),
        ]);
      }
      await this.dataInstanceRepository.deleteDataInstances(activityInstanceUids, true);
      await this.loadMission();
      return await this.router.navigate(['home/MissionInfo/' + this.missionInfoUid], { queryParams: { activityNode: null } });
    });
  }

  async duplicateSelectedNodes(activityInstanceUids: string[], isActivityFromOtherMission = false) {
    await this.loadingScreenService.show(async () => {
      if (!this.missionData) return console.warn('Mission data not found');

      const missionActivities = this.missionData.fieldValues['activities'];
      if (!missionActivities) return console.warn('Mission activities field not found');

      let duplicatedDataInstancesData = undefined;
      if (isActivityFromOtherMission)
        duplicatedDataInstancesData = await this.dataInstanceRepository.duplicateDataInstances({
          instanceUids: activityInstanceUids,
          dropExternalReferencesOfTypes: ['EnumRef<Activity>'],
        });
      else
        duplicatedDataInstancesData = await this.dataInstanceRepository.duplicateDataInstances({
          instanceUids: activityInstanceUids,
          dropExternalReferencesOfTypes: [],
        });

      if (!duplicatedDataInstancesData || duplicatedDataInstancesData.dataInstances.length === 0) {
        return console.warn('Could not duplicate activity');
      }

      for (const newActivityInstance of duplicatedDataInstancesData.dataInstances) {
        const newActivityInstanceIdentifier = await newActivityInstance.identifier;
        const originalActivityUid = Object.entries(duplicatedDataInstancesData.originalToDuplicateMapping).find(
          ([_key, value]) => value === newActivityInstanceIdentifier,
        )?.[0];

        if (!originalActivityUid) return console.warn('Could not find original activity uid for duplicated activity');

        try {
          if (!isActivityFromOtherMission) {
            // const nodePosition = await lastValueFrom(this.requestService.getNodePosition(this.dataService.currentGameId, originalActivityUid));
            const nodePosition = await this.nodePositionRepository.get(originalActivityUid, false, NodeCategory.Activity);
            nodePosition.position = Vector2.add(nodePosition.position, new Vector2([50, 50]));
          } else {
            const nodePosition = await this.nodePositionRepository.get(newActivityInstanceIdentifier, false, NodeCategory.Activity);
            // For activities from another mission we set the coords to 0;0 so that they will get moved to the view of the user
            nodePosition.position = Vector2.zero;
          }
        } catch (err) {
          void err;
          Logger.warn('Could not load node position for activity with uid: ' + originalActivityUid + ', falling back to 0;0');
          await this.nodePositionRepository.create(
            {
              dataInstanceUid: newActivityInstanceIdentifier,
              positionX: 0,
              positionY: 0,
            },
            NodeCategory.Activity,
          );
        }
      }

      await missionActivities.set([
        ...new Set([
          ...missionActivities.getDeserializedValue(FieldType.LIST, missionActivities.value),
          ...(await Promise.all(duplicatedDataInstancesData.dataInstances.flatMap((d) => d.identifier))),
        ]),
      ]);

      await this._loadMission(this.missionInfoUid!);
    });
  }

  openOverview() {
    return this.setSelectedNode(undefined);
  }

  private tryGetMissionUid(): string | undefined {
    if (!this.missionInfo) return undefined;
    return this.missionInfo?.fieldValues['mission']?.value as string | undefined;
  }

  private async _loadMission(missionInfoUid: string) {
    const missionInfo = await this.dataInstanceRepository.get(missionInfoUid);
    const currentMissionUid = missionInfo.fieldValues['mission'];

    if (!currentMissionUid || !currentMissionUid.value || !(await currentMissionUid.validate())) {
      throw new Error('Invalid mission');
    }

    const currentMission = await this.dataInstanceRepository.get(currentMissionUid.value);
    const activityFieldValue = currentMission.fieldValues['activities'];

    if (!activityFieldValue) {
      throw new Error('Invalid activities field value');
    }

    const activityUids = activityFieldValue.getDeserializedValue(FieldType.LIST, activityFieldValue.value) as string[];
    this.nodes = await Promise.all(
      activityUids.map((uid) => {
        return (async () => {
          try {
            return await this.nodePositionRepository.get(uid, undefined, NodeCategory.Activity);
          } catch (err) {
            void err;
            return await NodePosition.deserialize(
              {
                dataInstanceUid: uid,
                positionX: 0,
                positionY: 0,
              },
              NodeCategory.Activity,
            );
          }
        })();
      }),
    );

    return missionInfo;
  }
}
