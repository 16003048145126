import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FieldEditorComponent } from '@services/dynamic-field.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataInstance } from '@services/entities';
import { DataInstanceRepository, StructTypeRepository } from '@services/repositories';
import { FieldType, FieldValue } from '@services/entities/helpers';
import { FileEndpoints } from '@services/api';
import { lastValueFrom, Subscription } from 'rxjs';

@Component({
  selector: 'app-text-with-audio-field',
  templateUrl: './text-with-audio-field.component.html',
  styleUrl: './text-with-audio-field.component.scss',
})
export class TextWithAudioFieldComponent implements OnInit, OnDestroy, FieldEditorComponent<string> {
  @Input({ required: true }) data!: FieldValue;

  @ViewChild('audioModal') audioModal!: ElementRef;

  value!: string;
  currentResource?: DataInstance;
  currentAudioName = 'No audio selected';

  private audioChangeSubscription?: Subscription;

  constructor(
    private modalService: NgbModal,
    private dataInstanceRepository: DataInstanceRepository,
    private structRepository: StructTypeRepository,
    private fileEndpoints: FileEndpoints,
  ) {}

  async ngOnInit() {
    this.value = this.data.getDeserializedValue(FieldType.STRING, this.data.value);
    this.currentResource = await this.dataInstanceRepository.get(this.value);
    if (!this.currentResource.fieldValues['audio']) {
      const textWithAudioStruct = await this.structRepository.get('TextWithAudio');
      const audioField = textWithAudioStruct.fields['audio'];
      this.currentResource.fieldValues['audio'] = new FieldValue({
        field: audioField,
        value: '',
        dataInstanceUid: this.value,
      });
    } else if (this.currentResource.fieldValues['audio'].value) {
      this.currentAudioName = (await lastValueFrom(this.fileEndpoints.getFileMeta(this.currentResource.fieldValues['audio']?.value))).name;
    }
    this.audioChangeSubscription = this.currentResource.fieldValues['audio']?.onChange.subscribe(async (audioField) => {
      const audioValue = audioField.value;
      if (audioValue) {
        this.currentAudioName = (await lastValueFrom(this.fileEndpoints.getFileMeta(audioField.value))).name;
      } else {
        this.currentAudioName = 'No audio selected';
      }
    });
  }

  ngOnDestroy() {
    this.audioChangeSubscription?.unsubscribe();
  }

  async addStructInstance() {
    const dataInstance = await this.dataInstanceRepository.create('TextWithAudio');
    await this.data.set(dataInstance);

    this.currentResource = dataInstance;
  }

  openAudioModal() {
    this.modalService.open(this.audioModal);
  }

  isAudioFilledIn(): boolean {
    if (!this.currentResource) return false;
    const audioField = this.currentResource.fieldValues['audio'];
    return !!audioField && !!audioField.value;
  }
}
