import { Repository } from './Repository';
import { FieldEditor } from '@services/entities';
import { Injectable } from '@angular/core';
import { FieldEditorEndpoints } from '../api';
import { from, lastValueFrom, map, Observable, shareReplay } from 'rxjs';
import { Cache } from '../utils';
import { GeneratedFieldEditor } from '../types/generated';
import { DirtyHandling } from '@services/decorators/DirtyHandling';

type Identifier = { structTypeId: string; fieldId: string };

@Injectable({ providedIn: 'root' })
export class FieldEditorRepository extends Repository<FieldEditor, Identifier> {
  private readonly cache = new Cache<FieldEditor>();

  private allRequest?: Observable<Promise<FieldEditor[]>>;

  constructor(private fieldEditorEndpoints: FieldEditorEndpoints) {
    super();
  }

  @DirtyHandling()
  public override async save(entity: FieldEditor): Promise<void> {
    await lastValueFrom(this.fieldEditorEndpoints.updateFieldEditor(entity.structId, await entity.serialize()));
  }

  public override async delete(_entity: FieldEditor): Promise<void> {
    // This method is not supported as there is no backend equivalent
    throw new Error('Method not implemented.');
  }

  public override async create(data: GeneratedFieldEditor): Promise<FieldEditor> {
    const fieldEditor = await FieldEditor.deserialize(await lastValueFrom(this.fieldEditorEndpoints.createFieldEditor(data)));
    return this.cache.set(fieldEditor.id, fieldEditor, 5);
  }

  public async getAll(): Promise<FieldEditor[]> {
    if (this.cache.allKeysPresent) return Object.values(this.cache.getAll());

    if (this.allRequest !== undefined) {
      return await lastValueFrom(this.allRequest);
    }

    this.allRequest = from(this.fieldEditorEndpoints.getFieldEditors()).pipe(
      map(async (response) => {
        const fieldEditors = await Promise.all(
          response.map(async (fieldEditor) => {
            const id = this.buildId(fieldEditor.structId, fieldEditor.fieldId);
            if (this.cache.isValid(id)) return this.cache.get(id)!.value;
            return await FieldEditor.deserialize(fieldEditor);
          }),
        );
        return this.cache.setAll(fieldEditors, (f) => f.id, 5);
      }),
      shareReplay(1),
    );

    const data = await lastValueFrom(this.allRequest);
    this.cache.setAll(data, (d) => d.id, 5);
    this.allRequest = undefined;
    return data;
  }

  public override async get({ structTypeId, fieldId }: Identifier, skipCache: boolean = false): Promise<FieldEditor> {
    const id = this.buildId(structTypeId, fieldId);
    if (!skipCache && this.cache.isValid(id)) {
      return this.cache.get(id)!.value;
    }

    if (this.requests[id] !== undefined) {
      return await lastValueFrom(this.requests[id]);
    }

    this.requests[id] = from(this.getAll()).pipe(
      map(async (allFieldEditors) => {
        const fieldEditor = allFieldEditors.find((fe) => fe.id === id);
        if (!fieldEditor) throw new Error('Field editor not found');
        return this.cache.set(this.buildId(structTypeId, fieldId), fieldEditor!, 5);
      }),
      shareReplay(1),
    );

    const data = await lastValueFrom(this.requests[id]);
    delete this.requests[id];
    return data;
  }

  private buildId(structTypeId: string, fieldId: string): string {
    return `${structTypeId}.${fieldId}`;
  }
}
