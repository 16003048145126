import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService } from '@services/UI-elements/alert-service';
import { FieldType, FieldValue } from '@services/entities/helpers';
import { Logger } from '@services/utils';
import { FieldEditorComponent } from '@services/dynamic-field.service';

@Component({
  selector: 'app-check-field',
  templateUrl: './check-field.component.html',
  styleUrls: ['./check-field.component.scss'],
})
export class CheckFieldComponent implements OnInit, FieldEditorComponent<boolean> {
  @Input({ required: true }) data!: FieldValue;
  @Output() checkToggled: EventEmitter<boolean> = new EventEmitter<boolean>();

  value!: boolean;

  constructor(private alertService: AlertService) {}

  ngOnInit() {
    this.value = this.data.getDeserializedValue(FieldType.BOOLEAN, this.data.value);
    if (!this.data) this.checkToggled.emit(true);
    else this.checkToggled.emit(this.value);
  }

  async update() {
    try {
      await this.data.set(this.value);
      this.checkToggled.emit(this.value);
    } catch (e) {
      Logger.error(`Failed to save field ${this.data.field.fieldId}: `, e);
      this.alertService.error(`Failed to save field value`);
    }
  }
}
