import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom, Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Logger, sleep, Vector2 } from '@services/utils';
import { addTransformers, FieldType } from '@services/entities/helpers';
import { LoadingScreenService } from '@services/UI-elements/loading-screen.service';
import { DataInstance, NodePosition } from '@services/entities';
import { DataInstanceRepository, NodePositionRepository } from '@services/repositories';
import { NodeCategory } from '../../../../models/types/NodeCategory';
import { FlowchartType } from '../../flowchart/flowchart.component';
import { ConfirmationModalService } from '@services/UI-elements/confirmation-modal.service';

@Component({
  selector: 'app-module-editor',
  templateUrl: './module-editor.component.html',
  styleUrls: ['./module-editor.component.scss'],
})
export class ModuleEditorComponent implements OnInit, OnDestroy {
  module?: DataInstance;
  moduleId?: string;
  moduleName?: string;
  loading = false;
  kennisNodeUid?: string;
  currentKennisNode?: NodePosition = undefined;

  nodes: NodePosition[] = [];

  protected readonly environment = environment;
  protected readonly FlowchartType = FlowchartType;

  private routeSub?: Subscription;
  private routerSubscription?: Subscription;
  private currentKennisNodeSubscription?: Subscription;
  private kennisNodesSubscription?: Subscription;
  private moduleUid?: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loadingScreenService: LoadingScreenService,
    private dataInstanceRepository: DataInstanceRepository,
    private nodePositionRepository: NodePositionRepository,
    private confirmationModalService: ConfirmationModalService,
  ) {}

  async setSelectedNode(node?: NodePosition) {
    if (this.loading) return;

    if (!node) {
      return await this.router.navigate([], { queryParams: { kennisNode: null } }).then();
    }

    return await this.router.navigate([], { queryParams: { kennisNode: node.dataInstanceUid } }).then();
  }

  ngOnInit(): void {
    this.routeSub = this.activatedRoute.params.subscribe(async (params) => {
      this.moduleUid = params['moduleUid'];
      this.loading = true;

      await this.loadModule();

      this.loading = false;
    });

    this.routerSubscription = this.activatedRoute.queryParams.subscribe(async (params) => {
      this.kennisNodeUid = params['kennisNode'];

      while (this.loading) {
        await sleep(100);
      }

      this.currentKennisNode = this.nodes.find((a) => a.dataInstanceUid === this.kennisNodeUid);
      this.nodePositionRepository.currentActivity.next(this.kennisNodeUid ? this.currentKennisNode : undefined);
    });
  }

  ngOnDestroy(): void {
    this.routeSub?.unsubscribe();
    this.currentKennisNodeSubscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
    this.kennisNodesSubscription?.unsubscribe();
  }

  async loadModule() {
    return this.loadingScreenService.show(async () => {
      if (!this.moduleUid) throw new Error('Failed to load module, no module uid provided');

      this.module = await this._loadModule(this.moduleUid);
      this.moduleId = this.module.fieldValues['moduleId']?.value as string;
      this.moduleName = this.module.fieldValues['displayName']?.value as string;
    });
  }

  async onAddKennisNode(title: string) {
    if (!title) throw new Error('No title provided');

    return this.loadingScreenService.show(async () => {
      // Create new node
      const newNode = await this.dataInstanceRepository.create('KennisNode');
      await newNode.fieldValues['title']!.set(title);

      // Add to kennisboom
      if (!this.module) return;

      const currentKennisboom = await this.dataInstanceRepository.get(this.module.fieldValues['kennisboom']!.value as string);
      const kennisNodes = currentKennisboom.fieldValues['kennisNodes'];

      if (!kennisNodes) {
        throw new Error('Could not find kennisNodes in kennisboom');
      }

      if (kennisNodes) {
        if (kennisNodes.value) {
          await kennisNodes.set([...kennisNodes.getDeserializedValue(FieldType.LIST, kennisNodes.value), await newNode.identifier]);
        } else {
          await kennisNodes.set([await newNode.identifier]);
        }
      }

      const node = await this.nodePositionRepository.create(
        {
          dataInstanceUid: await newNode.identifier,
          positionX: 0,
          positionY: 0,
        },
        NodeCategory.Kennis,
      );

      node.name = title;
      this.nodes = [...this.nodes, this.addNodeTransformer(node)];

      await this.setSelectedNode(node);
    });
  }

  openOverview() {
    return this.setSelectedNode(undefined);
  }

  async deleteKennisNode(kennisNodeDataInstanceUid: string) {
    if (!this.module || !this.currentKennisNode) return;

    if (!(await lastValueFrom(this.confirmationModalService.confirm('Are you sure you want to delete this kennisNode?')))) return;

    const [nodeInstance, nodePosition] = await Promise.all([
      this.dataInstanceRepository.get(kennisNodeDataInstanceUid),
      this.nodePositionRepository.get(kennisNodeDataInstanceUid, undefined, NodeCategory.Kennis),
    ]);

    // This is intentionally not wrapped in a Promise.all, because the backend fails when we do.
    await this.nodePositionRepository.delete(nodePosition);
    await this.dataInstanceRepository.delete(nodeInstance, true);

    const currentKennisboom = await this.dataInstanceRepository.get(this.module.fieldValues['kennisboom']?.value as string);
    const kennisNodes = currentKennisboom.fieldValues['kennisNodes'];
    if (!kennisNodes || !kennisNodes.value) {
      Logger.error('No kennisNodes found in kennisboom');
      return;
    }

    await kennisNodes.set(
      (kennisNodes.getDeserializedValue(FieldType.LIST, kennisNodes.value) as string[]).filter(
        (uid: string) => uid !== kennisNodeDataInstanceUid,
      ),
    );

    // Delete the node from the outgoingConnections field of other nodes
    await Promise.all(
      (kennisNodes.getDeserializedValue(FieldType.LIST, kennisNodes.value) as string[]).map(async (uid) => {
        const node = await this.dataInstanceRepository.get(uid);

        const outgoingConnections = node.fieldValues['outgoingConnections'];
        if (!outgoingConnections || !outgoingConnections.value) {
          return;
        }

        await outgoingConnections.set(
          (outgoingConnections.getDeserializedValue(FieldType.LIST, outgoingConnections.value) as string[]).filter(
            (connection: string) => connection !== kennisNodeDataInstanceUid,
          ),
        );
      }),
    );

    this.nodes = this.nodes.filter((n) => n.dataInstanceUid !== kennisNodeDataInstanceUid);
    await this.setSelectedNode(undefined);
  }

  private async _loadModule(moduleUid: string) {
    const moduleDataInstance = await this.dataInstanceRepository.get(moduleUid);

    for (const fieldValue of Object.values(moduleDataInstance.fieldValues)) {
      if (!fieldValue) continue;
      await fieldValue.loadDataInstances();
    }

    const kennisboomInstanceUid = moduleDataInstance.fieldValues['kennisboom']!.value;
    if (!kennisboomInstanceUid) throw new Error('Could not load kennisboom with uid: ' + kennisboomInstanceUid);

    const kennisboom = await this.dataInstanceRepository.get(kennisboomInstanceUid);
    if (!kennisboom) throw new Error('Could not load kennisboom with uid: ' + kennisboomInstanceUid);

    const nodesFieldValue = kennisboom.fieldValues['kennisNodes'];
    if (!nodesFieldValue) {
      // We don't error here as we can just continue with no nodes
      Logger.warn(`Kennisboom ${kennisboom.__uid} has no kennisNodes field`);
    }

    const nodeUids = nodesFieldValue
      ? ((nodesFieldValue.getDeserializedValue(FieldType.LIST, nodesFieldValue.value) as string[]) ?? [])
      : [];

    this.nodes = await Promise.all(
      nodeUids.map(async (nodeUid) => {
        let nodePosition: NodePosition | undefined = undefined;

        try {
          nodePosition = await this.nodePositionRepository.get(nodeUid, undefined, NodeCategory.Kennis);

          if (!nodePosition || (nodePosition.position.x === 0 && nodePosition.position.y === 0)) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error();
          }
        } catch (err) {
          void err;
          const positionFieldValue = (await this.dataInstanceRepository.get(nodeUid)).fieldValues['position']!;
          const position = positionFieldValue.getDeserializedValue(FieldType.VECTOR2, positionFieldValue.value) ?? Vector2.zero;

          // Typescript thinks nodePosition is not defined here, but there is a possibility that it is
          if (nodePosition === undefined) {
            nodePosition = await this.nodePositionRepository.create(
              {
                dataInstanceUid: nodeUid,
                positionX: position.x,
                positionY: position.y,
              },
              NodeCategory.Kennis,
            );
          } else {
            nodePosition.position = new Vector2([position.x, position.y]);
          }
        }

        return this.addNodeTransformer(nodePosition);
      }),
    );

    return moduleDataInstance;
  }

  private addNodeTransformer(node: NodePosition) {
    return addTransformers(node, {
      position: {
        set: (position: Vector2) => new Vector2([position.x, position.y * -1]),
        get: (position: Vector2) => new Vector2([position.x, position.y * -1]),
      },
    });
  }
}
