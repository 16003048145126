import { ApplicationRef, EventEmitter } from '@angular/core';
import { Logger, Try } from '../utils';
import GTInjector from '../GTInjector';
import { SavingIndicatorService } from '../UI-elements/saving-indicator.service';
import { Identifiable } from '../types/Identifiable';
import { Savable } from '../types/Savable';
import { OnChanges } from '@services/types/OnChanges';
import { AlertService } from '@services/UI-elements/alert-service';
import { BootstrapClass } from '../../models/types/BootstrapClass';

/**
 * We use symbols in this decorator to make sure we can never have any overlapping properties. This is important because
 * we're adding extra properties to the object that we're decorating, and we want to make sure we don't accidentally
 * overwrite any user-provided properties.
 */

const initialized = Symbol('initialized');
const debouncedSaveTimeout = Symbol('debouncedSaveTimeout');

const triggerSave = Symbol('triggerSave');
const isObject = Symbol('isObject');
const toProxyObject = Symbol('toProxyObject');
const subscribeToUpdateEvents = Symbol('subscribeToUpdateEvents');
const isIgnoredKey = Symbol('isIgnoredKey');

// We keep a list of all symbols we know, so we can add them to the ignoreKeys setting
const symbols = [initialized, debouncedSaveTimeout, triggerSave, isObject, toProxyObject, subscribeToUpdateEvents, isIgnoredKey];

/**
 * A decorator to make the class it is applied to automatically save when any properties change. If the property is an
 * object or array, it will get replaced with a proxy that triggers the save function when it changes. It will also
 * subscribe to any `updateEmitter` property that it can find and trigger the save function when it emits.
 *
 * > **IMPORTANT!**
 * >
 * > If the class you are applying this decorator to does **NOT** implement a `save()` method and does **NOT** have an
 * > `updateEmitter` property it will error! (Update emitters can be easily added by using the `@UpdateEmitter()` decorator)
 *
 * @param settings { debounceTime?: number = 500 }
 */
export function AutoSave(settings: { debounceTime?: number; ignoreKeys?: string[] } | undefined = undefined) {
  return function <
    T extends {
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      new (...args: any[]): {
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
      } & Partial<Savable> &
        Partial<Identifiable> &
        Partial<OnChanges>;
    },
  >(constructor: T) {
    return class extends constructor {
      private readonly [initialized]: boolean = false;
      private [debouncedSaveTimeout]?: ReturnType<typeof setTimeout>;

      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      constructor(...args: any[]) {
        super(...args);

        const propNames = Object.getOwnPropertyNames(this) as (keyof this)[];

        for (const propName of propNames) {
          const backingPropertyName = `#_${String(propName)}`;
          const isIgnored = this[isIgnoredKey](propName);
          const originalValue = this[propName];

          // Wrap objects in proxies, unless they are ignored. In that case, just use the original value
          this[backingPropertyName] = this[isObject](originalValue) && !isIgnored ? this[toProxyObject](originalValue) : originalValue;
          this[subscribeToUpdateEvents](this[backingPropertyName]);

          // We re-define the property to add a new setter that calls _triggerSave() when the property is changed
          Object.defineProperty(this, propName, {
            set: (value: unknown) => {
              const valueChanged = this[backingPropertyName] !== value;
              const isIgnored = this[isIgnoredKey](propName);
              const shouldSave = valueChanged && !isIgnored && this[initialized];

              // Actually set the new value
              this[backingPropertyName] = this[isObject](value) && !isIgnored ? this[toProxyObject](value) : value;

              if (valueChanged && this.onChanges) this.onChanges.next({ [propName]: value });
              if (shouldSave) this[triggerSave](propName.toString());
            },
            get: () => this[backingPropertyName],
          });
        }

        this[initialized] = true;
      }

      /**
       * Internal method to trigger the save function after a certain amount of time
       */
      private [triggerSave](change?: string) {
        if (this[debouncedSaveTimeout]) clearTimeout(this[debouncedSaveTimeout]);

        this[debouncedSaveTimeout] = setTimeout(async () => {
          const [saveService, applicationRef] = await Promise.all([
            GTInjector.inject(SavingIndicatorService),
            GTInjector.inject(ApplicationRef),
          ]);

          const result = await saveService.show(async () => {
            if (!this.save) throw new Error('Class must implement save()');

            const identifier = await Try(
              () => this.identifier,
              () => this['__uid'],
            );

            Logger.debug(
              `[AutoSave] Triggering save for: ${constructor.name}${identifier ? `[${identifier}]` : ''}` +
                (change ? `, changed: '${change}'` : ''),
            );

            return Try(
              () => this.save!(),
              async (err) => {
                Logger.error(`Could not save ${constructor.name}${identifier ? `[${identifier}]` : ''}`, err);
                (await GTInjector.inject(AlertService)).showAlert(`Failed to save ${constructor.name}`, BootstrapClass.DANGER, 1e4);
              },
            );
          });

          applicationRef.tick();
          return result;
        }, settings?.debounceTime ?? 500);
      }

      /**
       * Helper method to check if a value is an object (but not null).
       */
      private [isObject](value: unknown): value is object {
        return typeof value === 'object' && value !== null;
      }

      /**
       * Converts an object into a proxy. Triggers save when any property of the object changes.
       * This method is recursive to handle nested objects.
       */
      private [toProxyObject]<T extends object>(obj: T): T {
        Object.values(obj).forEach(this[subscribeToUpdateEvents].bind(this));

        return new Proxy(obj, {
          set: (target, prop, value) => {
            const hasChanges = target[prop as keyof T] !== value;
            const isIgnored = this[isIgnoredKey](prop);

            if (hasChanges) {
              target[prop as keyof T] =
                this[isObject](value) && !isIgnored
                  ? this[toProxyObject](value) // Recursively wrap nested objects
                  : value;

              if (!isIgnored) {
                this[triggerSave](target instanceof Array ? `Array[${prop.toString()}]` : prop.toString());
              }
            }

            return true;
          },
          deleteProperty: (target, prop) => {
            if (prop in target) {
              // @ts-expect-error We know that prop exists in target.
              delete target[prop];
              if (!this[isIgnoredKey](prop)) this[triggerSave](target instanceof Array ? `Array[${prop.toString()}]` : prop.toString());
              return true;
            }
            return false;
          },
        });
      }

      private [subscribeToUpdateEvents](obj: unknown) {
        if (typeof obj === 'object' && obj !== null && 'updateEmitter' in obj && obj.updateEmitter instanceof EventEmitter) {
          obj.updateEmitter.subscribe(this[triggerSave].bind(this));
        }
      }

      private [isIgnoredKey](key: string | { toString: () => string }): boolean {
        const ignoreKeys: string[] = [
          ...(settings?.ignoreKeys || []),
          'updateEmitter',
          'onChanges',
          'shouldPersist',
          'lastSavedValueHash',
          ...symbols.map((s) => s.toString()),
        ].filter(Boolean);

        return ignoreKeys.includes(key.toString());
      }
    };
  };
}
