<div class="d-flex justify-content-between gap-2">
  <span class="align-content-center"> X: </span>
  <input
    (change)="update()"
    *ngIf="data"
    [(ngModel)]="value.x"
    class="form-control form-control-sm"
    id="vector-x"
    placeholder="X"
    type="number"
  />
  <span class="align-content-center"> Y: </span>
  <input
    (change)="update()"
    *ngIf="data"
    [(ngModel)]="value.y"
    class="form-control form-control-sm"
    id="vector-y"
    placeholder="Y"
    type="number"
  />
</div>
