import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ContextMenuOptions, ShapeType, TargetType, VisualTarget } from './canvas-editor.types';
import { Subscription } from 'rxjs';
import { Preset, Presets } from '../visual-editor/presets';
import { DataInstance } from '@services/entities';
import { Logger, Try, Vector2 } from '@services/utils';
import { FieldType, FieldValue } from '@services/entities/helpers';
import { DataInstanceRepository, EnumTypeRepository, StructTypeRepository } from '@services/repositories';
import { FieldEditorComponent } from '@services/dynamic-field.service';

@Component({
  selector: 'app-canvas-editor',
  templateUrl: './canvas-editor.component.html',
  styleUrls: ['./canvas-editor.component.scss'],
})
export class CanvasEditorComponent implements OnInit, OnDestroy, FieldEditorComponent<string> {
  @Input({ required: true }) data!: FieldValue;

  value: string = '';

  dataInstance: DataInstance | undefined;

  draggableInstances: DataInstance[] = [];
  targetInstances: DataInstance[] = [];
  visualElementInstances: DataInstance[] = [];
  mapPinInstances: DataInstance[] = [];

  visualTargets: Record<string, VisualTarget> = {};
  visualTargetNames: Record<string, string> = {};

  backgroundInstance?: DataInstance;
  canvasRatio: Vector2 = new Vector2([1, 1]);
  selected = '';

  protected contextMenu: ContextMenuOptions = {};
  protected readonly Object = Object;
  private routeQueryParamSub?: Subscription;
  private routeParamSub?: Subscription;

  constructor(
    private dataInstanceRepository: DataInstanceRepository,
    private structTypeRepository: StructTypeRepository,
    private enumTypeRepository: EnumTypeRepository,
  ) {}

  async ngOnInit(): Promise<void> {
    if (!this.data) {
      throw new Error('Data input is required');
    }
    await this.loadEditor(this.data.dataInstanceUid);
  }

  ngOnDestroy() {
    this.routeQueryParamSub?.unsubscribe();
    this.routeParamSub?.unsubscribe();
  }

  async addVisualElement(
    shapeType: 'Circle' | 'Rectangle',
    position = new Vector2([0.5, 0.5]),
    size = new Vector2([0.04, 0.06]),
    radius = 0.04,
  ) {
    if (!this.dataInstance) return;

    const visualElementInstance = await this.dataInstanceRepository.create('VisualElement');
    await visualElementInstance.fieldValues['position']!.set(position);

    // The creation of a visual element makes a shape instance as well, but this might be the wrong shape
    const shapeFieldValue = visualElementInstance.fieldValues['shape']!;
    const shapeInstance = await this.dataInstanceRepository.get(shapeFieldValue.value);
    let shapeDefaultValue = shapeFieldValue.field.defaultValue;
    if (!shapeDefaultValue) shapeDefaultValue = (await this.enumTypeRepository.get('Shape')).options[0];
    if (shapeDefaultValue === shapeType) {
      if (shapeType === 'Circle') {
        await shapeInstance.fieldValues['radius']!.set(radius);
      } else {
        await shapeInstance.fieldValues['size']!.set(size);
      }
    } else {
      // The default shape is not the wanted one, so we have to create it manually
      const newShapeInstance = await this.dataInstanceRepository.create(shapeType);
      if (shapeType === 'Circle') {
        await newShapeInstance.fieldValues['radius']!.set(radius);
      } else {
        await newShapeInstance.fieldValues['size']!.set(size);
      }
      await visualElementInstance.fieldValues['shape']!.set(newShapeInstance);
      await this.dataInstanceRepository.delete(shapeInstance, true);
    }

    const fieldValue = this.dataInstance.fieldValues['visualElements']!;
    await fieldValue.set([...fieldValue.getDeserializedValue(FieldType.LIST, fieldValue.value), await visualElementInstance.identifier]);

    this.visualElementInstances.push(visualElementInstance);
    this.visualTargetNames[visualElementInstance.randomIdentifier] = await visualElementInstance.identifier;
    await this.setVisualTargets();
  }

  async addMapPinLocation(position = new Vector2([0.5, 0.5]), panelPosition = new Vector2([0.5, 0.5])) {
    if (!this.dataInstance) return;

    const instance = await this.dataInstanceRepository.create('MapPinLocation');
    await instance.fieldValues['position']!.set(position);
    await instance.fieldValues['panelPosition']!.set(panelPosition);

    const fieldValue = this.dataInstance.fieldValues['mapLocations'];
    if (fieldValue) await fieldValue.set([...fieldValue.getDeserializedValue(FieldType.LIST, fieldValue.value), await instance.identifier]);
    else await this.dataInstance.setFieldValue('mapLocations', [await instance.identifier]);

    this.mapPinInstances.push(instance);
    this.visualTargetNames[instance.randomIdentifier] = (await instance.identifier) as string;
    await this.setVisualTargets();
  }

  async addInstance(
    structType: 'Draggable' | 'DropPoint' | 'DropArea' | 'ClickTarget',
    shapeType: 'Rectangle' | 'Circle',
    position = new Vector2([0.5, 0.5]),
    size = new Vector2([0.04, 0.06]),
    radius = 0.04,
  ) {
    if (!this.dataInstance) return;

    const instanceStruct = await this.dataInstanceRepository.create(structType);

    const visualElementInstanceUid = instanceStruct.fieldValues['visualElement'];
    if (!visualElementInstanceUid) {
      Logger.warn('No visual element found for instance ' + instanceStruct.__uid);
      return;
    }

    const visualElementInstance = await this.dataInstanceRepository.get(visualElementInstanceUid.value as string);
    await visualElementInstance.fieldValues['position']!.set(position);

    // The creation of a visual element makes a shape instance as well, but this might be the wrong shape
    const shapeFieldValue = visualElementInstance.fieldValues['shape']!;
    const shapeInstance = await this.dataInstanceRepository.get(shapeFieldValue.value);
    let shapeDefaultValue = shapeFieldValue.field.defaultValue;
    if (!shapeDefaultValue) shapeDefaultValue = (await this.enumTypeRepository.get('Shape')).options[0];
    if (shapeDefaultValue === shapeType) {
      if (shapeType === 'Circle') {
        await shapeInstance.fieldValues['radius']!.set(radius);
      } else {
        await shapeInstance.fieldValues['size']!.set(size);
      }
    } else {
      // The default shape is not the wanted one, so we have to create it manually
      const newShapeInstance = await this.dataInstanceRepository.create(shapeType);
      if (shapeType === 'Circle') {
        await newShapeInstance.fieldValues['radius']!.set(radius);
      } else {
        await newShapeInstance.fieldValues['size']!.set(size);
      }
      await visualElementInstance.fieldValues['shape']!.set(newShapeInstance);
      await this.dataInstanceRepository.delete(shapeInstance, true);
    }

    switch (structType) {
      case 'Draggable': {
        const fieldValue = this.dataInstance.fieldValues['draggables'];
        if (!fieldValue) throw new Error('Field draggables not found in activity' + this.dataInstance.__uid);
        await fieldValue.set([...fieldValue.getDeserializedValue(FieldType.LIST, fieldValue.value), await instanceStruct.identifier]);
        this.draggableInstances.push(instanceStruct);
        break;
      }
      case 'DropPoint':
      case 'DropArea':
      case 'ClickTarget': {
        const fieldValue = this.dataInstance.fieldValues['targets'];
        if (!fieldValue) throw new Error('Field targets not found in activity' + this.dataInstance.__uid);
        await fieldValue.set([...fieldValue.getDeserializedValue(FieldType.LIST, fieldValue.value), await instanceStruct.identifier]);
        this.targetInstances.push(instanceStruct);
        break;
      }
      default:
        return;
    }

    this.visualTargetNames[instanceStruct.randomIdentifier] = (await instanceStruct.identifier) as string;
    await this.setVisualTargets();
  }

  async addPreset(preset: Preset) {
    if (!this.dataInstance || !preset) return;

    for (const shape of preset.shapes) {
      if (shape.isVisualElement) {
        if (shape.type === 'Circle') {
          await this.addVisualElement(shape.type, new Vector2([shape.x, shape.y]), undefined, shape.radius);
        } else {
          await this.addVisualElement(
            shape.type,
            new Vector2([shape.x, shape.y]),
            new Vector2([shape.size?.width ?? 0.04, shape.size?.height ?? 0.06]),
          );
        }
      } else {
        if (shape.type === 'Circle') {
          await this.addInstance('ClickTarget', shape.type, new Vector2([shape.x, shape.y]), undefined, shape.radius);
        } else {
          await this.addInstance(
            'ClickTarget',
            shape.type,
            new Vector2([shape.x, shape.y]),
            new Vector2([shape.size?.width ?? 0.04, shape.size?.height ?? 0.06]),
          );
        }
      }
    }
  }

  async getInstances(field: 'draggables' | 'targets' | 'visualElements' | 'mapLocations') {
    if (!this.dataInstance) return;

    const fieldValue = this.dataInstance.fieldValues[field];
    const uids = (fieldValue?.getDeserializedValue(FieldType.LIST, fieldValue.value) as string[]) ?? [];

    const instances = (await Promise.all(
      uids.map(async (uid) => {
        const instance = await this.dataInstanceRepository.get(uid);
        const nameField = (await this.structTypeRepository.get(instance.dataType)).fields['name'];

        if (nameField) {
          let fieldValue = instance.fieldValues['name']?.value;
          if (!fieldValue) fieldValue = await instance.identifier;

          this.visualTargetNames[instance.randomIdentifier] = fieldValue as string;
        } else {
          this.visualTargetNames[instance.randomIdentifier] = (await instance.identifier) as string;
        }

        return instance;
      }),
    )) as DataInstance[];

    switch (field) {
      case 'draggables':
        this.draggableInstances = instances;
        break;
      case 'targets':
        this.targetInstances = instances;
        break;
      case 'visualElements':
        this.visualElementInstances = instances;
        break;
      case 'mapLocations':
        this.mapPinInstances = instances;
        break;
    }

    await this.setVisualTargets();
  }

  async setVisualTargets() {
    const vTargets = { ...this.visualTargets };

    const currentInstanceUids = await Promise.all(
      [...this.draggableInstances, ...this.targetInstances, ...this.visualElementInstances, ...this.mapPinInstances].map(
        (instance) => instance.identifier,
      ),
    );

    for (const uid in this.visualTargets) {
      if (!currentInstanceUids.includes(uid)) {
        delete vTargets[uid];
      }
    }

    for (const instance of [...this.draggableInstances, ...this.targetInstances, ...this.visualElementInstances, ...this.mapPinInstances]) {
      // Add the visual targets that are new
      if (!Object.prototype.hasOwnProperty.call(vTargets, await instance.identifier)) {
        let visualElementInstance = undefined;
        let targetType = TargetType.DROP_TARGET;
        let hideBorder = false;
        let isCorrect = false;

        switch (instance.dataType) {
          case 'VisualElement':
            visualElementInstance = instance;
            targetType = TargetType.VISUAL_ELEMENT;
            break;
          case 'ClickTarget':
            targetType = TargetType.CLICK_TARGET;
            break;
          case 'MapPinLocation':
            targetType = TargetType.MAP_PIN;
            break;
          case 'Draggable':
            targetType = TargetType.DRAGGABLE;
            break;
        }

        if (instance.dataType !== 'VisualElement' && instance.dataType !== 'MapPinLocation') {
          const visualElementField = instance.fieldValues['visualElement'];
          if (!visualElementField) {
            Logger.warn('No visual element found for instance ' + instance.__uid);
            continue;
          }

          visualElementInstance = await this.dataInstanceRepository.get(visualElementField.value as string);

          if (!visualElementInstance) {
            Logger.warn('Visual element not found for instance ' + instance.__uid);
            continue;
          }

          if (Object.keys(instance.fieldValues).includes('isCorrect')) {
            const isCorrectField = instance.fieldValues['isCorrect'];
            if (isCorrectField) {
              isCorrect = isCorrectField.getDeserializedValue(FieldType.BOOLEAN, isCorrectField.value);
            }
          }

          if (Object.keys(instance.fieldValues).includes('hideInteractableIndicator')) {
            const hideBorderField = instance.fieldValues['hideInteractableIndicator'];
            if (hideBorderField) {
              hideBorder = hideBorderField.getDeserializedValue(FieldType.BOOLEAN, hideBorderField.value);
            }
          }
        }

        switch (instance.dataType) {
          case 'MapPinLocation': {
            const positionFieldValue = instance.fieldValues['position']!;
            const position =
              positionFieldValue.getDeserializedValue(FieldType.VECTOR2, positionFieldValue.value) ?? new Vector2([0.5, 0.5]);

            const panelPositionFieldValue = instance.fieldValues['panelPosition']!;
            const panelPosition =
              panelPositionFieldValue.getDeserializedValue(FieldType.VECTOR2, panelPositionFieldValue.value) ?? new Vector2([0.5, 0.5]);

            const identifier = await instance.identifier;
            vTargets[identifier] = {
              type: ShapeType.PIN,
              position: { dataInstanceUid: identifier, fieldValue: { field: 'position', value: position } },
              panelPosition: {
                dataInstanceUid: identifier,
                fieldValue: { field: 'panelPosition', value: panelPosition },
              },
              targetType: targetType,
              uid: identifier,
              name: this.visualTargetNames[instance.randomIdentifier],
            } as VisualTarget; // todo: fix this type (tip: use 'satisfies' instead)

            break;
          }

          default: {
            const shapeInstanceField = visualElementInstance!.fieldValues['shape'];
            if (!shapeInstanceField) {
              Logger.warn('VisualElement instance does not have a shape');
              return;
            }

            const shapeInstance = await this.dataInstanceRepository.get(shapeInstanceField.value as string);
            const shapeType = shapeInstance.dataType as 'Circle' | 'Rectangle';

            const positionFieldValue = visualElementInstance!.fieldValues['position']!;
            const position =
              positionFieldValue.getDeserializedValue(FieldType.VECTOR2, positionFieldValue.value) ?? new Vector2([0.5, 0.5]);

            const size =
              Try(
                () => {
                  const sizeFieldValue = shapeInstance!.fieldValues['size'];
                  return sizeFieldValue?.getDeserializedValue(FieldType.VECTOR2, sizeFieldValue.value);
                },
                (e) => shapeType == 'Rectangle' && Logger.error('Could not parse VisualElement instance size', e),
              ) ?? new Vector2([0.04, 0.06]);

            const radius =
              Try(
                () => {
                  const radiusFieldValue = shapeInstance.fieldValues['radius'];
                  return radiusFieldValue?.getDeserializedValue(FieldType.FLOAT, radiusFieldValue.value);
                },
                (e) => shapeType == 'Circle' && Logger.error('Could not parse VisualElement instance radius', e),
              ) ?? 0.04;

            let media =
              Try(
                () => {
                  const mediaFieldValue = visualElementInstance!.fieldValues['media'];
                  return mediaFieldValue?.value;
                },
                (e) => Logger.error('Could not parse VisualElement instance media', e),
              ) ?? '';

            const placeableMedia = media ? await this.dataInstanceRepository.get(media) : undefined;

            if (media) {
              if (!placeableMedia) {
                Logger.warn('Placeable media not found for instance ' + media);
                continue;
              }

              const imageFieldValue = Try(() => placeableMedia.fieldValues['image']);

              if (imageFieldValue) {
                media = imageFieldValue.value as string;
              } else {
                media = Try(() => placeableMedia.fieldValues['image'])?.value ?? '';
              }
            }

            const instanceIdentifier = await instance.identifier;

            vTargets[instanceIdentifier] = {
              type: shapeType,
              position: {
                dataInstanceUid: await visualElementInstance!.identifier,
                fieldValue: { field: 'position', value: position },
              },
              size: {
                dataInstanceUid: await shapeInstance.identifier,
                fieldValue: { field: 'size', value: size },
              },
              radius: {
                dataInstanceUid: await shapeInstance.identifier,
                fieldValue: { field: 'radius', value: radius },
              },
              media: {
                dataInstanceUid: await placeableMedia?.identifier,
                fieldValue: { field: 'media', value: media },
              },
              targetType: targetType,
              isCorrect: isCorrect,
              hide: hideBorder,
              uid: instanceIdentifier,
              name: this.visualTargetNames[instance.randomIdentifier],
            } as VisualTarget; // todo: fix this type (tip: use 'satisfies' instead)

            break;
          }
        }
      }
    }

    this.visualTargets = vTargets;
  }

  onUpdateName(update: { instance: DataInstance; name: string }) {
    this.visualTargetNames[update.instance.randomIdentifier] = update.name;
  }

  onShapeSelected(uid: string) {
    this.selected = Object.entries(this.visualTargetNames).find(([_, value]) => value === uid)?.[0] ?? '';
  }

  async deleteInstance(instance: DataInstance, field: 'draggables' | 'targets' | 'visualElements' | 'mapLocations') {
    if (!this.dataInstance) return;

    const fieldValue = this.dataInstance.fieldValues[field];
    if (!fieldValue) {
      Logger.warn('Field not found');
      return;
    }

    const identifier = await instance.identifier;
    await fieldValue.set(fieldValue.getDeserializedValue(FieldType.LIST, fieldValue.value).filter((uid) => uid !== identifier));
    await this.dataInstanceRepository.delete(instance, true);
    await this.getInstances(field);
  }

  private async loadEditor(activityInstanceUid: string) {
    Logger.debug(`Loading editor for ${activityInstanceUid}`);

    this.dataInstance = await this.dataInstanceRepository.get(activityInstanceUid);

    const backgroundField = Try(() => this.dataInstance!.fieldValues['background']);
    if (!backgroundField) {
      Logger.warn('No background found');
      return;
    }

    this.backgroundInstance = await this.dataInstanceRepository.get(
      backgroundField.getDeserializedValue(FieldType.IMAGE_REF, backgroundField.value),
    );

    Logger.debug('background instance: ', this.backgroundInstance);

    // const backgroundFieldValue = placeableMedia.fieldValues.find((fieldValue) => {
    //   return fieldValue.field === 'image' || fieldValue.field === 'video' || fieldValue.field === 'color';
    // }) as FieldValue<string> | undefined;

    const canvasRatioFieldValue = this.dataInstance.fieldValues['canvasRatio'];

    if (canvasRatioFieldValue) {
      this.canvasRatio = canvasRatioFieldValue.getDeserializedValue(FieldType.VECTOR2, canvasRatioFieldValue.value);
      if (this.canvasRatio.x === 0 || this.canvasRatio.y === 0) {
        this.canvasRatio = new Vector2([1, 1]);
      }
    }

    switch (this.dataInstance.dataType) {
      case 'DragAndDropActivity': {
        this.contextMenu = {
          'Draggable objects': [
            {
              label: 'Rectangle draggable',
              action: (x: number, y: number) => this.addInstance('Draggable', 'Rectangle', new Vector2({ x, y })),
            },
          ],
          'Drop targets': [
            {
              label: 'Rectangle drop point',
              action: (x: number, y: number) => this.addInstance('DropPoint', 'Rectangle', new Vector2({ x, y })),
            },
            {
              label: 'Rectangle drop area',
              action: (x: number, y: number) => this.addInstance('DropArea', 'Rectangle', new Vector2({ x, y })),
            },
          ],
          'Visual Elements': [
            {
              label: 'Add Rectangle',
              action: (x: number, y: number) => this.addVisualElement('Rectangle', new Vector2({ x, y })),
            },
            {
              label: 'Add Circle',
              action: (x: number, y: number) => this.addVisualElement('Circle', new Vector2({ x, y })),
            },
          ],
        };
        await this.getInstances('draggables');
        await this.getInstances('targets');
        await this.getInstances('visualElements');
        break;
      }
      case 'ClickActivity': {
        this.contextMenu = {
          Targets: [
            {
              label: 'Add Rectangle',
              action: (x: number, y: number) => this.addInstance('ClickTarget', 'Rectangle', new Vector2({ x, y })),
            },
            {
              label: 'Add Circle',
              action: (x: number, y: number) => this.addInstance('ClickTarget', 'Circle', new Vector2({ x, y })),
            },
          ],
          'Visual Elements': [
            {
              label: 'Add Rectangle',
              action: (x: number, y: number) => this.addVisualElement('Rectangle', new Vector2({ x, y })),
            },
            {
              label: 'Add Circle',
              action: (x: number, y: number) => this.addVisualElement('Circle', new Vector2({ x, y })),
            },
          ],
          Presets: Presets.map((preset) => ({
            label: preset.name,
            action: () => this.addPreset(preset),
          })),
        };
        await this.getInstances('targets');
        await this.getInstances('visualElements');
        break;
      }
      case 'Map': {
        this.contextMenu = {
          Pins: [
            {
              label: 'Add pin',
              action: (x: number, y: number) => this.addMapPinLocation(new Vector2({ x, y }), new Vector2({ x, y })),
            },
          ],
          'Visual Elements': [
            {
              label: 'Add Rectangle',
              action: (x: number, y: number) => this.addVisualElement('Rectangle', new Vector2({ x, y })),
            },
            {
              label: 'Add Circle',
              action: (x: number, y: number) => this.addVisualElement('Circle', new Vector2({ x, y })),
            },
          ],
        };
        await this.getInstances('mapLocations');
        await this.getInstances('visualElements');
        break;
      }
    }
  }
}
