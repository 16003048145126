import { Entity } from './Entity';
import { AutoSave } from '../decorators/AutoSave';
import { GeneratedFieldEditor } from '../types/generated';
import GTInjector from '../GTInjector';
import { FieldEditorRepository } from '@services/repositories';
import { FieldEditorType } from '@services/types/FieldEditorType';

export interface NewFieldEditor {
  fieldId: string;
  structId: string;
  editorType: FieldEditorType;
  showIf?: string;
  showResource?: boolean;
  position: number;
  scope?: string;
  displayField?: string;
}

@AutoSave()
export class FieldEditor extends Entity {
  public readonly id: string;

  public readonly fieldId: string;
  public readonly structId: string;

  public editorType: FieldEditorType;
  public showIf?: string;
  public showResource?: boolean;
  public position: number;
  public scope?: string;
  public displayField?: string;

  constructor({ fieldId, structId, editorType, showIf, showResource, position, scope, displayField }: NewFieldEditor) {
    super();
    this.id = `${structId}.${fieldId}`;

    this.fieldId = fieldId;
    this.structId = structId;
    this.editorType = editorType;
    this.showIf = showIf;
    this.showResource = showResource;
    this.position = position;
    this.scope = scope;
    this.displayField = displayField;
  }

  public override get identifier() {
    return this.id;
  }

  public static async deserialize(data: GeneratedFieldEditor): Promise<FieldEditor> {
    const fieldEditorType = (Object.values(FieldEditorType) as string[]).includes(data.editorType)
      ? (data.editorType as FieldEditorType)
      : FieldEditorType.Default;

    const fieldEditor = new FieldEditor({
      structId: data.structId,
      fieldId: data.fieldId,
      editorType: fieldEditorType,
      showIf: data.showIf,
      showResource: data.showResource,
      position: data.position,
      scope: data.scope,
      displayField: data.displayField,
    });

    await fieldEditor.updateLastSavedValueHash();
    return fieldEditor;
  }

  /**
   * @deprecated This method should be rarely used by a developer because @AutoSave() should handle most cases. If you
   * find any cases where auto saving does not work, please open a bug report so it can be fixed.
   */
  public async save() {
    return (await GTInjector.inject(FieldEditorRepository)).save(this);
  }

  public async serialize(): Promise<Readonly<GeneratedFieldEditor>> {
    return Object.freeze({
      editorType: this.editorType,
      showIf: this.showIf,
      showResource: this.showResource,
      position: this.position,
      scope: this.scope,
      displayField: this.displayField,
      structId: this.structId,
      fieldId: this.fieldId,
    });
  }
}
