import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService, InvalidRoleError } from '@services/authorization/auth.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingScreenService } from '@services/UI-elements/loading-screen.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
// TODO move this component to a logical place (it shouldn't be in home-screen. either it's own folder or a login/auth folder)
//todo jonas: turn this into an auth module where login is a route in the newly created auth module. connect auth module with app-routing module with lazy loading
export class LoginComponent implements OnInit {
  @Output() loggedIn = new EventEmitter<void>();

  wrongCredentials = false;
  invalidRole = false;
  loginFailed = false;
  redirectUrl: string | null = null;
  routerSubscription?: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loadingScreenService: LoadingScreenService,
  ) {}

  ngOnInit() {
    this.routerSubscription = this.activatedRoute.queryParams.subscribe(async (params) => {
      this.redirectUrl = params['redirectUrl'];
    });
  }

  onLogin(username: string, password: string) {
    this.resetErrors();
    return this.loadingScreenService.show(async () => {
      try {
        await firstValueFrom(this.authService.login(username, password));
        this.loggedIn.emit();

        if (!this.redirectUrl) {
          return this.router.navigate(['/home']);
        }

        if (this.redirectUrl.includes('?activityNode=')) {
          const queryParams = this.redirectUrl.split('?activityNode=')[1];
          return this.router.navigate([this.redirectUrl.split('?activityNode=')[0]], { queryParams: { activityNode: queryParams } }).then();
        } else if (this.redirectUrl.includes('?activity=')) {
          const queryParams = this.redirectUrl.split('?activity=')[1];
          return this.router.navigate([this.redirectUrl.split('?activity=')[0]], { queryParams: { activityNode: queryParams } }).then();
        } else if (this.redirectUrl && this.redirectUrl.includes('?kennisNode=')) {
          const queryParams = this.redirectUrl.split('?kennisNode=')[1];
          return this.router.navigate([this.redirectUrl.split('?kennisNode=')[0]], { queryParams: { kennisNode: queryParams } }).then();
        } else {
          return this.router.navigate([this.redirectUrl]).then();
        }
      } catch (e) {
        if (e instanceof InvalidRoleError) {
          this.invalidRole = true;
        } else if (e instanceof HttpErrorResponse && e.status >= 400 && e.status < 500) {
          this.wrongCredentials = true;
        } else {
          this.loginFailed = true;
        }
        throw e;
      }
    });
  }

  private resetErrors() {
    this.wrongCredentials = false;
    this.invalidRole = false;
    this.loginFailed = false;
  }
}
